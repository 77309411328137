@import '../../../styles/sсss/mixins';
@import '../../../styles/sсss/variables';

.welcome-user-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  &_popup-opened {
    height: 100vh;
    overflow: hidden;
  }
  &__content {
    @include flex-center;
    flex: 1 1 auto;
    background-image: url('../../../assets/images/background-1440px.svg');
    background-repeat: repeat-y;
    background-size: 100%;
  }
  &__info {
    @include flex-center;
    cursor: pointer;
    position: relative;
    @include size (100%, 100%);
    border-radius: 28px;
    background-color: #fff;
    z-index: 1;
    transition: all 0.2s linear;
    &:hover {
      transform: scale(1.05);
    }
    &-background {
      position: absolute;
      top: 6px;
      left: 4px;
      z-index: -1;
      background-color: $greenBackground;
      @include size (100%, 100%);
      border-radius: 28px;
    }
    &-inner {
      border-radius: 28px;
      border: 1px solid #000;
      @include size (100%, 100%);
      @include flex-center;
    }
  }
  &__title {
    max-width: 350px;
    @include text-format(500, 35px, 126.2%);
    text-align: center;
    margin: 0;
  }
  &__link {
    @include linkStyle;
    @include size (680px, 304px);
  }
}

.welcome-user-page__info::after {
  content: "";
  position: absolute;
  top: -98px;
  left: 63px;
  background-image: url('../images/welcome-user-panda.svg');
  @include backgroundImage;
  @include size (197px, 116px);
}

@media screen and (max-width: 1024px) {
  .welcome-user-page {
    &__content {
      background-image: url('../../../assets/images/background-1024px.svg');
    }
    &__link {
      max-width: 90%;
    }
  }
}

@media screen and (max-width: 850px) {
  .welcome-user-page {
    &__info::after {
      top: -88px;
      @include size (160px, 105px);
    }
    &__link {
      max-width: 80%;
    }
  }
}

@media screen and (max-width: 768px) {
  .welcome-user-page {
    &__content {
      background-image: url('../../../assets/images/background-768px.svg');
    }
  }
}

@media screen and (max-width: 650px) {
  .welcome-user-page {
    &__link {
      max-width: 80%;
    }
    &__title {
      max-width: 80%;
      @include text-format(500, 30px, 126.2%);
    }
  }
}

@media screen and (max-width: 500px) {
  .welcome-user-page {
    &__info::after {
      left: 43px;
      top: -78px;
      @include size (140px, 95px);
    }
    &__link {
      max-width: 90%;
      max-height: 250px;
    }
  }
}

@media screen and (max-width: 400px) {
  .welcome-user-page {
    &__info::after {
      top: -55px;
      @include size (110px, 62px);
    }
    &__title {
      max-width: 80%;
      @include text-format(500, 28px, 1.28);
    }
  }
}

@media screen and (max-width: 320px) {
  .welcome-user-page {
    &__content {
      background-image: url('../../../assets/images/background-320px.svg');
    }
  }
}
