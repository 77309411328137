@import '../../../../styles/sсss/mixins';
@import '../../../../styles/sсss/variables';

.save-popup {
  @include size (100vw, 100vh);
  @include flex-center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: rgba(255, 255, 255, 0.42);
  backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.1s, opacity 0.1s linear;
  &_opened {
    visibility: visible;
    opacity: 1;
  }
  &__container {
    box-sizing: border-box;
    position: relative;
    @include flex-center;
    @include size (821px, 597px);
    border: 1px solid $greenText;
    border-radius: 18px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  &__rocket {
    @include reset;
    @include size(197px, 393px);
    background-image: url('../../images/save-popup-rocket.svg');
    @include backgroundImage;
    background-size: cover;
    position: absolute;
    top: 86px;
    left: 32px;
  }
  &__panda {
    @include reset;
    @include size(202px, 256px);
    background-image: url('../../images/save-popup-panda.svg');
    @include backgroundImage;
    background-size: cover;
    position: absolute;
    bottom: -5px;
    right: 25px;
  }
  &__text {
    position: relative;
    box-sizing: border-box;
    @include text-format(500, 28px, 74.2%);
    margin: 0 0 13px 0;
    text-align: center;
    padding-top: 26px;
    @include size(458px, 96px);
    background-image: url('../../images/save-popup-text-background.svg');
    @include backgroundImage;
  }
}

.save-popup__text::after {
  content: "";
  position: absolute;
  top: -50px;
  right: -39px;
  background-image: url('../../images/save-popup-stars.svg');
  @include backgroundImage;
  @include size (60px, 89px);
}

@media screen and (max-width: 1024px) {
  .save-popup {
    &__panda {
      @include size(160px, 216px);
    }
  }
}

@media screen and (max-width: 920px) {
  .save-popup {
    &__container {
      max-width: 90%;
    }
  }
}

@media screen and (max-width: 700px) {
  .save-popup {
    &__rocket {
      display: none;
    }
    &__panda {
      @include size(140px, 200px);
    }
    &__text {
      @include text-format(500, 24px, 74.2%);
      @include size(390px, 96px);
    }
  }
}

@media screen and (max-width: 550px) {
  .save-popup {
    &__panda {
      @include size(120px, 170px);
    }
    &__text {
      @include text-format(500, 22px, 74.2%);
      padding-top: 30px;
      @include size(390px, 96px);
      &::after {
        top: -150px;
        right: 20px;
        @include size (40px, 69px);
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .save-popup {
    &__container {
      max-width: 90%;
      height: 500px;
    }
    &__rocket {
      display: block;
      @include size(167px, 303px);
      top: 50px;
    }
    &__panda {
      @include size(120px, 170px);
    }
    &__text {
      @include text-format(500, 20px, 1.3);
      background: none;
      width: 50%;
      height: fit-content;
      &::after {
        top: -150px;
        right: -20px;
        @include size (40px, 69px);
      }
    }
  }
}
