@import '../../../../../../styles/sсss/mixins.scss';
@import '../../../../../../styles/sсss/variables.scss';

.portal {
  &__close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
    @include size(39px, 39px);
    border-radius: 50%;
    border: 1px solid;
    cursor: pointer;
    background: $whiteBackground;
    padding: 4px;
  }
}