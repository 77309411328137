@import '../../../../styles/sсss/mixins';
@import '../../../../styles/sсss/variables';

.test-results__title {
  max-width: 600px;
  margin: 0 auto 60px;
  &-congrats {
    @include text-format(500, 45px, 1.33);
    width: 100%;
    text-align: center;
    margin: 0;
    &:first-of-type {
      margin-bottom: 25px;
    }
  }
  &_highlighted {
    display: block;
    mask-image: url('../../images/background-congrats-green.svg');
    mask-repeat: no-repeat;
    mask-size: cover;
    &-orange {
      background-color: rgba(246, 212, 145, 1);
    }
    &-green {
      background-color: rgba(212, 231, 121, 1);
    }
  }
}

@media screen and (max-width: 900px) {
  .test-results__title {
    max-width: 500px;
    margin: 0 auto 90px;
    &-congrats {
      @include text-format(500, 38px, 1.33);
      &:first-of-type {
        margin-bottom: 20px;
      }
    }
    &_highlighted {
      mask-size: cover;
      &-orange {
        background-color: rgba(246, 212, 145, 1);
      }
      &-green {
        background-color: rgba(212, 231, 121, 1);
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .test-results__title {
    max-width: 450px;
    margin: 0 auto 60px;
    &-congrats {
      @include text-format(500, 32px, 1.33);
      &:first-of-type {
        margin-bottom: 20px;
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .test-results__title {
    max-width: 80%;
    margin: 0 auto 60px;
    &-congrats {
      @include text-format(500, 26px, 1.33);
      &:first-of-type {
        margin-bottom: 20px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .test-results__title {
    max-width: fit-content;
    margin: 0 auto 35px;
    &-congrats {
      @include text-format(500, 22px, 1.33);
    }
  }
}
