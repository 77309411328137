@import '../../../styles/sсss/mixins';
@import '../../../styles/sсss/variables';

.text {
  text-align: justify;
  margin: 0 0 10px 0px;
  @include text-format(500, 24px, auto);
  &:last-of-type {
    margin: 0 0 30px 0px;
  }
}

.text-answers {
  text-align: justify;
  margin: 0 0 10px 40px;
  @include text-format(500, 24px, auto);
}

@media screen and (max-width: 1000px) {
  .text {
    @include text-format (500, 20px, 1.28);
    &-answers {
      @include text-format (500, 20px, 1.28);
    }
  }
}

@media screen and (max-width: 480px) {
  .text {
    @include text-format (500, 18px, 1.28);
    &-answers {
      @include text-format (500, 18px, 1.28);
    }
  }
}