@import '../../../../styles/sсss/mixins';
@import '../../../../styles/sсss/variables';

.buy-practicum__button {
  position: relative;
  @include size (272px, 80px);
  @include text-format(500, 26px, .95);
  background-color: #fff;
  @include backgroundImage;
  background-image: url('../../images/buy-button-bg.svg');
  background-size: cover;
  border: 1px solid #000;
  border-radius: 14.85px;
  color: #000;
  cursor: pointer;
  transition: transform .5s;
  &-link {
    @include linkStyle;
  }
  &::before {
    content: "";
    position: absolute;
    top: -40px;
    left: -50px;
    background-image: url('../../images/buy-practicum-decoration-left.svg');
    @include backgroundImage;
    @include size (54px, 86px);
  }
  &::after {
    content: "";
    position: absolute;
    top: 30px;
    left: 260px;
    background-image: url('../../images/buy-practicum-decoration-right.svg');
    @include backgroundImage;
    @include size (54px, 86px);
  }
  &:hover {
    transform: scale(1.1);
  }
}

@media screen and (max-width: 1200px) {
  .buy-practicum__button {
    @include size (262px, 70px);
    @include text-format(500, 26px, .95);
  }
}

@media screen and (max-width: 800px) {
  .buy-practicum__button {
    @include size (230px, 60px);
    @include text-format(500, 22px, .95);
    &::before {
      @include size (44px, 66px);
    }
    &::after {
      left: 230px;
      @include size (44px, 66px);
    }
  }
}

@media screen and (max-width: 480px) {
  .buy-practicum__button {
    @include size (196px, 55px);
    @include text-format(500, 20px, 1.12);
    &::before {
      top: -18px;
      left: -25px;
      @include size (24px, 36px);
    }
    &::after {
      top: 35px;
      left: 192px;
      @include size (24px, 36px);
    }
  }
}

@media screen and (max-width: 380px) {
  .buy-practicum {
    &__button {
      @include size (186px, 55px);
      &::before {
        top: -18px;
        left: -20px;
      }
      &::after {
        top: 38px;
        left: 182px;
      }
    }
  }
}

