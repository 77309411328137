@import '../../../styles/sсss/mixins';
@import '../../../styles/sсss/variables';

.page-title {
  color: $greenText;
  min-height: 75px;
  @include text-format(500, 55px, 67.7%);
  @include backgroundImage;
  background-image: url('../images/page-title-img.svg');
  background-size: auto;
  background-position: right;
  padding: 10px 60px 0 50px;
}

@media screen and (max-width: 550px) {
  .page-title {
    @include text-format(500, 50px, 67.7%);
  }
}

@media screen and (max-width: 400px) {
  .page-title {
    padding: 10px 40px 0 30px;
    background-size: 30%;
    @include text-format(500, 40px, 67.7%);
  }
}
