@import "../../../../../styles/sсss/mixins";

.results {
    @include flex-center-column;
    justify-content: space-around;
    height: 100%;
    
    &__count {
        background-color: #fff;
        @include text-format(400, 2em, 1em);
        text-align: center;
        padding: 15px 40px;
        border: #9168AD 2px solid;
        border-radius: 5px;
    }
}

.answers-list {
    display: flex;
    padding: 0;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1200px;

    &__answer {
        width: 140px;
        @include flex-center;
        margin-bottom: 10px;
        color:  #1A0A25;
        text-align: center;
        font-weight: 500;
        background-color: #fff;
        padding: 15px;
        border: #9168AD 2px solid;
        border-radius: 5px;
        position: relative;

        &::before {
            display: block;
            position: absolute;
            top: -10px;
            right: -10px;
            content: "";
            @include size(25px, 25px);
        }
    }

    .answer-correct::before {
        background: url('../../images/correct.svg');
}

    .answer-incorrect::before {
        background: url('../../images/incorrect.svg');
    }
}

.button {
    padding: 10px 20px;
    @include text-format(500, 1.4em, 1em);
    color: #274870;
    border-radius: 5px;
    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease;
    }
}

@media (max-width: 480px) {
    .results {
        gap: 10px;

        &__count {
            @include text-format(400, 1.4em, 1em);
            padding: 15px 40px;
        }
    }

    .answers-list {
        width: 100%;
    
        &__answer {
            width: 36%;
            padding: 10px;
            @include text-format(500, 0.8em, 1em);
        }
    }
}