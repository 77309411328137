@import '../../../../styles/sсss/mixins';
@import '../../../../styles/sсss/variables';

.main {
  &__courses {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 51px;
    column-gap: 84px;
    margin: 30px 30px 193px;
    max-width: 1060px;
    &-link {
      @include reset;
      width: 200px;
      height: 100px;
      @include text-format(400, 35px, 23px);
      border: 1px solid;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      box-shadow: 0px 2px 2px $greenText;
      position: relative;
      text-decoration: none;
      color: inherit;
      cursor: pointer;
    }
    &-container {
      position: relative;
    }
    &-books {
      background-image: url('../../../../assets/images/books.svg');
      @include backgroundImage;
      @include size(54px, 43px);
      position: absolute;
      top: -45px;
      left: 9px;
    }
    &-text {
      cursor: pointer;
      text-decoration: none;
    }
  }
}
#first {
  &::after {
    @include psevdoElement;
    background-image: url('../../images/1-4class.png');
    @include backgroundImage;
    @include size(104%, 104%);
    position: absolute;
    bottom: -5px;
    right: -7px;
    z-index: -1;
  }
}

#second {
  &::after {
    @include psevdoElement;
    background-image: url('../../images/5class.png');
    @include backgroundImage;
    @include size(101%, 101%);
    position: absolute;
    top: 5px;
    left: 0px;
    z-index: -1;
  }
}
#third {
  &::after {
    @include psevdoElement;
    background-image: url('../../images/6class.png');
    @include backgroundImage;
    @include size(109%, 112%);
    position: absolute;
    bottom: -3px;
    right: -9px;
    z-index: -1;
  }
}
#fourth {
  &::after {
    @include psevdoElement;
    background-image: url('../../images/7class.png');
    @include backgroundImage;
    @include size(107%, 107%);
    position: absolute;
    top: -5px;
    left: -5px;
    z-index: -1;
  }
}
#fifth {
  &::after {
    @include psevdoElement;
    background-image: url('../../images/8class.png');
    @include backgroundImage;
    @include size(102%, 102%);
    position: absolute;
    top: 5px;
    left: -7px;
    z-index: -1;
  }
}
#sixth {
  &::after {
    @include psevdoElement;
    background-image: url('../../images/9class.png');
    @include backgroundImage;
    @include size(102%, 102%);
    position: absolute;
    top: -4px;
    left: -10px;
    z-index: -1;
  }
}
#oge {
  &::after {
    @include psevdoElement;
    background-image: url('../../images/oge.png');
    @include backgroundImage;
    @include size(104%, 104%);
    position: absolute;
    top: 5px;
    left: 0px;
    z-index: -1;
  }
}
#ege {
  &::after {
    @include psevdoElement;
    background-image: url('../../images/ege.png');
    @include backgroundImage;
    @include size(107%, 107%);
    position: absolute;
    top: -8px;
    left: -12px;
    z-index: -1;
  }
}

@media screen and (max-width: 768px) {
  .main {
    &__courses {
      row-gap: 30px;
      column-gap: 30px;
      margin: 30px 50px 193px;
    }
    &-books {
      display: none;
    }
  }
}
@media screen and (max-width: 645px) {
  .main {
    &__courses {
      margin: 30px 30px 205px;
      &-link {
        height: 50px;
        @include text-format(500, 16px, 77%);
      }
    }
  }
  #first {
    &::after {
      background-image: url('../../images/1-4class-320.svg');
      @include size(108%, 108%);
      bottom: -2px;
      right: -7px;
    }
  }
  #second {
    &::after {
      background-image: url('../../images/5class-320.svg');
      @include size(103%, 103%);
      top: 2px;
      left: -2px;
    }
  }
  #third {
    &::after {
      background-image: url('../../images/6class-320.svg');
      @include size(103%, 103%);
      top: 2px;
      left: -2px;
    }
  }
  #fourth {
    &::after {
      background-image: url('../../images/7class-320.svg');
      @include size(103%, 103%);
      top: 2px;
      left: -2px;
    }
  }
  #fifth {
    &::after {
      background-image: url('../../images/8class-320.svg');
      @include size(103%, 103%);
      top: 2px;
      left: -2px;
    }
  }
  #sixth {
    &::after {
      background-image: url('../../images/9class-320.svg');
      @include size(103%, 103%);
      top: 2px;
      left: -2px;
    }
  }
  #oge {
    &::after {
      background-image: url('../../images/oge-320.svg');
      @include size(103%, 103%);
      top: 2px;
      left: -2px;
    }
  }
  #ege {
    &::after {
      background-image: url('../../images/ege-320.svg');
      @include size(103%, 103%);
      top: 2px;
      left: -2px;
    }
  }
}
