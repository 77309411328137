@import '../../../../styles/sсss/mixins';
@import '../../../../styles/sсss/variables';

.teachers {
  &__list {
    &-item {
      display: flex;
      gap: 55px;
      flex-direction: row-reverse;
      &:first-of-type {
        margin-bottom: 198px;
        flex-direction: row;
      }
    }
  }
  &__img {
    @include size(360px, 492px);
    border-radius: 10px;
  }
  &-info {
    &__title {
      box-sizing: border-box;
      @include text-format (500, 32px, 120%);
      text-align: center;
      @include size (300px, 60px);
      margin: 0 auto 28px;
      @include backgroundImage;
      packground-position: 10px;
      padding-top: 10px;
      &-anna {
        background-image: url('../../images/teachers-background-anna.svg');
      }
      &-polina {
        background-image: url('../../images/teachers-background-polina.svg');
      }
    }
    &__list {
      padding: 0;
      list-style-type: none;
      margin-bottom: 24px;
      &-item {
        @include text-format (500, 24px, 144%);
      }
    }
    &__text {
      @include text-format (500, 24px, 34.56px);
      margin: 0;
      text-align: justify;
      &:last-of-type {
        margin-top: 10px;
      }
    }
  }
}

.teachers-info__list .teachers-info__list-item::before {
  content: "";
  background-color: #F08E47;
  font-weight: bold;
  display: inline-block;
  @include size (10px, 10px);
  border-radius: 50%;
  margin-right: 10px;
}

@media screen and (max-width: 1024px) {
  .teachers {
    &__img {
      margin-top: 30px;
      @include size(306px, 418px);
    }
    &-info {
      &__list {
        &-item {
          @include text-format (500, 22px, 32px);
        }
      }
      &__text {
        @include text-format (500, 22px, 32px);
      }
    }
    &__panda-img {
      @include size (38%, 401px);
    }
  }
}

@media screen and (max-width: 800px) {
  .teachers {
    &__list {
      &-item {
        position: relative;
        gap: 130px;
        flex-direction: column;
        align-items: center;
        &:first-of-type {
          flex-direction: column;
        }
        &:first-of-type::after {
          z-index: -1;
          content: "";
          position: absolute;
          top: -130px;
          left: -90px;
          background-image: url('../../images/teachers-anna-back-img.svg');
          @include backgroundImage;
          @include size (600px, 717px);
        }
        &:last-of-type::after {
          z-index: -1;
          content: "";
          position: absolute;
          top: -130px;
          left: -90px;
          background-image: url('../../images/teachers-polina-back-img.svg');
          @include backgroundImage;
          @include size (666px, 666px);
        }
      }
    }
    &__img {
      margin-top: 0;
      @include size(350px, 482px);
      position: relative;
      &-anna {
        &::after {
          content: "";
          position: absolute;
          top: -42px;
          left: 100px;
          background-image: url('../../images/teachers-anna-back-img.svg');
          @include backgroundImage;
          @include size (100px, 100px);
        }
      }
    }
    &-info {
      &__list {
        max-width: 440px;
        &-item {
          @include text-format (500, 24px, 144%);
        }
      }
      &__text {
        @include text-format (500, 24px, 144%);
      }
    }
    &__panda-img {
      @include size (calc(100% - 350px), 350px);
    }
  }
}

@media screen and (max-width: 500px) {
  .teachers {
    padding: 30px 0 50px;
    background-image: none;
    &__list {
      &-item {
        position: relative;
        gap: 25px;
        &:first-of-type {
          margin-bottom: 80px;
        }
        &:first-of-type::after {
          top: -80px;
          left: calc((100% - 302px) / 2);
          @include size (302px, 465px);
        }
        &:last-of-type::after {
          top: -100px;
          left: calc((100% - 350px) / 2);
          @include size (350px, 460px);
        }
      }
    }
    &__img {
      @include size(218px, 298px);
    }
    &-info {
      &__title {
        @include text-format (500, 24px, 120%);
        @include size (250px, 45px);
      }
      &__list {
        max-width: 440px;
        &-item {
          @include text-format (500, 18px, 124%);
        }
      }
      &__text {
        @include text-format (500, 18px, 124%);
      }
    }
  }

  .teachers-info__list .teachers-info__list-item::before {
    @include size (7px, 7px);
    margin-right: 8px;
  }
}
