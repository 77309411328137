@import '../../../../../../../styles/sсss/mixins';
@import '../../../../../../../styles/sсss/variables';

.game-progress-bar {
  &__count {
    @include text-format(400, 20px, normal);
    @include reset;
    flex: 0 0 auto;
  }
}

@media screen and (max-width: 1154px) {
  .game-progress-bar {
    &__count {
      left: 1.15%;
    }
  }
}

@media screen and (max-width: 950px) {
  .game-progress-bar {
    &__count {
      @include text-format(450, 16px, normal);
    }
  }
}

@media screen and (max-width: 720px) {
  .progress-bar {
    &__count {
      left: 0.5%;
      @include text-format(400, 12px, normal);
    }
  }
}

@media screen and (max-width: 350px) {
  .progress-bar {
    &__count {
      @include text-format(400, 10px, normal);
    }
  }
}