@import '../../../../../../../styles/sсss/mixins';
@import '../../../../../../../styles/sсss/variables';

.game-progress-bar {
    box-sizing: border-box;
    position: relative;
    height: 45px;
    margin: 0 auto 20px;
    padding: 16px 20px;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    max-width: 1200px;

    &__back-image {
        margin-top: 7px;
        @include size(45px, 45px);
        background-size: cover;
        overflow: hidden;
    }
    &__back-btn {
        @include reset;
        border: none;
        background: none;
        cursor: pointer;
        @include flex-center;
    }
}

@media screen and (max-width: 1149px) {
    .game-progress-bar {
        height: 40px;
        padding: 13px 16px;
        &__back-image {
            margin-top: 5px;
            @include size(35px, 35px);
        }
    }
}

@media screen and (max-width: 450px) {
    .game-progress-bar {
        height: 44px;
        padding: 16px 2px;
    }
}

@media screen and (max-width: 380px) {
    .game-progress-bar {
        height: 27px;
        padding: 10px;
        &__back-image {
            margin-top: 3px;
            @include size(20px, 20px);
        }
    }
}