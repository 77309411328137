@import "../../../../../styles/sсss/mixins.scss";

.countdown {
    position: absolute;
    top:50%;
    left:50%;
    @include size(100px, 100px);
    transform: translateX(-50%) translateY(-50%);
    transition: all 0.2s ease-out;
}

.count {
    &-3  {
        background: url('../../images/3.svg') no-repeat;
        background-size: contain;    
    }

    &-2  {
        background: url('../../images/2.svg') no-repeat;
        background-size: contain;
    }

    &-1  {
        background: url('../../images/1.svg') no-repeat;
        background-size: contain;
    }
}