@import '../../../styles/sсss/mixins';
@import '../../../styles/sсss/variables';

.page-subtitle {
  @include text-format(500, 40px, 1.2);
  margin: 0 auto;
  text-align: center;
}

@media screen and (max-width: 550px) {
  .page-subtitle {
    @include text-format(500, 36px, 1.2);
  }
}

@media screen and (max-width: 400px) {
  .page-subtitle {
    @include text-format(500, 30px, 1.2);
  }
}
