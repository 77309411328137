@import '../../../../styles/sсss/mixins';
@import '../../../../styles/sсss/variables';

.tests {
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  @include flex-column;
  padding-bottom: 50px;
}
