@import '../../../../../../styles/sсss/mixins';
@import '../../../../../../styles/sсss/variables';

.progress-bar {
  &__count {
    position: absolute;
    left: 3%;
    @include text-format(400, 20px, normal);
    @include reset;
    @include text-format(450, 20px, normal);
    &-two {
      color: #98D1E3;
    }
    &-three {
      @include text-format(450, 20px, normal);
      color: #9998e3;
    }
    &-four {
      @include text-format(450, 20px, normal);
      color: $fourth-game-primary;
    }
  }
}

@media screen and (max-width: 1154px) {
  .progress-bar {
    &__count {
      left: 1.15%;
    }
  }
}

@media screen and (max-width: 950px) {
  .progress-bar {
    &__count {
      @include text-format(450, 16px, normal);
    }
  }
}

@media screen and (max-width: 720px) {
  .progress-bar {
    &__count {
      left: 0.5%;
      @include text-format(400, 12px, normal);
    }
  }
}

@media screen and (max-width: 450px) {
  .progress-bar {
    &__count {
      position: static;
    }
  }
}

@media screen and (max-width: 350px) {
  .progress-bar {
    &__count {
      @include text-format(400, 10px, normal);
    }
  }
}