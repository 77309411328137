@import '../../../../../../styles/sсss/mixins';
@import '../../../../../../styles/sсss/variables';

.progress-bar {
    box-sizing: border-box;
    position: relative;
    padding: 16px 20px;
    //margin: 0 auto;
    background-color: #fff;
    border-radius: 5px;
    @include flex-center;
    align-items: center;
    &--one {
        border: 1px solid $greenBackground;
        box-shadow: 4px 4px 5px $greenBackground;
    }
    &--two {
        border: 1px solid $blueBackground;
        box-shadow: 4px 4px 5px $blueBackground;
    }
    &--three {
        border: 1px solid #9168AD;
        box-shadow: 2px 2px 5px #9168AD;
    }
    &--four {
        border: 1px solid $fourth-game-primary;
    }
    &__back-image {
        margin-top: 7px;
        @include size(45px, 45px);
        background-size: cover;
        overflow: hidden;
    }
    &__back-btn {
        @include reset;
        border: none;
        background: none;
        cursor: pointer;
        @include flex-center;
    }
}

@media screen and (max-width: 1149px) {
    .progress-bar {
        height: 40px;
        padding: 13px 16px;
        &__back-image {
            margin-top: 5px;
            @include size(35px, 35px);
        }
    }
}

@media screen and (max-width: 450px) {
    .progress-bar {
        display: flex;
        justify-content: space-between;
        padding: 16px 10px;
    }
}

@media screen and (max-width: 380px) {
    .progress-bar {
        max-height: 31px;
        padding: 5px;
        &__back-image {
            margin-top: 3px;
            @include size(20px, 20px);
        }
    }
}