@import '../../../../../styles/sсss/mixins.scss';
@import '../../../../../styles/sсss/variables.scss';

.task {
  box-sizing: border-box;
  width: 100%;
  display: grid;
  grid-template-columns: 30% 65%;
  gap: 5%;
  align-self: stretch;
  color: $fourth-game-text-secondary;

  @media screen and (max-width: 950px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, minmax(320px, auto));
    grid-row: 1/2;
    grid-column: 1/3;
    gap: 40px;
  }

  @media screen and (max-width: 450px) {
    grid-template-rows: minmax(216px, auto) minmax(152px, auto);
    gap: 20px;
  }

  &__question {
    @include text-format(300, 40px, normal);
    background: $whiteBackground;
    border: 1px solid $fourth-game-primary;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    padding: 65px 26%;
    box-sizing: border-box;

    @media screen and (max-width: $md) {
      padding: 44px 19% 0 19%;
    }

    @media screen and (max-width: 950px) {
      width: 100%;
      max-width: 50%;
      justify-self: center;
      align-items: center;
      padding: 0 10% 0 10%;
    }

    @media screen and (max-width: $sm) {
      max-width: 300px;
    }

    @media screen and (max-width: 600px) {
      font-size: 36px;
    }

    @media screen and (max-width: 400px) {
      width: 203px;
      max-width: 100%;
      font-size: 32px;
      border-radius: 3.38px;
    }
  }

  &__answer-list {
    @include text-format(300, 40px, normal);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 24px 7.4%;
    list-style: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    @media screen and (max-width: $md) {
      gap: 18.5px 7.5%;
      font-size: 36px;
    }

    @media screen and (max-width: $sm) {
      gap: 20px 50px;
    }
    
    @media screen and (max-width: 600px) {
      font-size: 26px;
    }

    @media  screen and (max-width: 450px) {
      gap: 10px;
      font-size: 20px;
    }

    @media  screen and (max-width: $xs) {
      font-size: 16px;
    }
  }

  .answer-item {
    @include flex-center;
    background: $whiteBackground;
    border: 1px solid $fourth-game-primary;
    border-radius: 5px;
    padding: 4.61% 6.1%;
    text-align: center;
    position: relative;
    cursor: pointer;

    &[data-selected="true"] {
      border-color: $fourth-game-disabled;
    }

    &[data-task-checked="true"] {
      cursor: not-allowed;
    }

    &[data-correct="true"] {
      .answer-item__icon-wrapper {
        color: $fourth-game-correct;
      }
    }

    &[data-correct="false"] {
      .answer-item__icon-wrapper {
        color: $fourth-game-primary;
      }
    }

    &__text {
      cursor: text;
    }

    &__icon-wrapper {
      @include size(35px, 35px);
      @include flex-center;
      box-sizing: border-box;
      padding: 3px;
      background: $whiteBackground;
      border-radius: 50%;
      border: 1px solid;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(25%, -25%);
      cursor: default;

      @media screen and (max-width: 450px) {
        @include size(25px, 25px);
      }
    }

    &__icon {
      max-width: 100%;
      max-height: 100%;
      box-sizing: border-box;
    }

    @media screen and (max-width: 600px) {
      padding: 10px;
    }

  }
}