@import '../../../../../styles/sсss/mixins.scss';
@import '../../../../../styles/sсss/variables.scss';

.fourth-game {
  @include flex-center-column;
  box-sizing: border-box;
  min-height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  background: url('../../images//bg/layer-3-left-top.svg') 0 8.5% / 20%,
              url('../../images/bg/layer-3-right-bottom.svg') bottom right / 16.5%,
              url('../../images/bg/layer-3-right-top.svg') top right / 21.5%,
              url('../../images/bg/layer-3-left-bottom.svg') bottom left / 38.9%,
              url('../../images/bg/layer-2-left-top.svg') top left / 18.7%,
              url('../../images/bg/layer-2-right-top.svg') top right / 25.2%,
              url('../../images/bg/layer-2-left-bottom.svg') bottom left / 30%,
              url('../../images/bg/layer-1-left-bottom.svg') bottom left / 41%,
              url('../../images/bg/layer-1-right-bottom.svg') bottom right / 39%,
              url('../../images/bg/layer-1-left-top.svg') 5% 0 / 45%,
              $whiteBackground;
  background-repeat: no-repeat;
  padding: 50px 0 18px 0;

  @media screen and (max-width: $md) {
    padding: 24px 0 18px 0;
    background: url('../../images//bg/layer-3-left-top-md.svg') 0 9.5% / 15%,
                url('../../images/bg/layer-3-right-bottom-md.svg') bottom right / 15.2%,
                url('../../images/bg/layer-3-right-top-md.svg') top right / 24%,
                url('../../images/bg/layer-3-left-bottom-md.svg') bottom left / 47%,
                url('../../images/bg/layer-2-left-top-md.svg') top left / 27%,
                url('../../images/bg/layer-2-right-top-md.svg') top right / 27%,
                url('../../images/bg/layer-2-left-bottom-md.svg') bottom left / 34.5%,
                url('../../images/bg/layer-1-left-bottom-md.svg') bottom left / 36%,
                url('../../images/bg/layer-1-right-bottom-md.svg') bottom right / 37.3%,
                url('../../images/bg/layer-1-left-top-md.svg') 16% 0 / 47%,
                $whiteBackground;
    background-repeat: no-repeat;
  }

  @media screen and (max-width: $sm) {
    padding: 42px 0 19px 0;
    background: url('../../images//bg/layer-3-left-top-sm.svg') 0 5.5% / 34.5%,
                url('../../images/bg/layer-3-right-bottom-sm.svg') bottom right / 24%,
                url('../../images/bg/layer-3-right-top-sm.svg') top right / 36%,
                url('../../images/bg/layer-3-left-bottom-sm.svg') bottom left / 69%,
                url('../../images/bg/layer-2-left-top-sm.svg') top 1% left / 34.5%,
                url('../../images/bg/layer-2-right-top-sm.svg') top right / 44%,
                url('../../images/bg/layer-2-left-bottom-sm.svg') bottom left / 42.5%,
                url('../../images/bg/layer-1-left-bottom-sm.svg') bottom left / 45.5%,
                url('../../images/bg/layer-1-right-bottom-sm.svg') bottom right / 41%,
                url('../../images/bg/layer-1-left-top-sm.svg') top left / 47.5%,
                $whiteBackground;
    background-repeat: no-repeat;
  }

  @media screen and (max-width: $xs) {
    padding: 22px 0 12px 0;
    background: url('../../images//bg/layer-3-left-top-xs.svg') 0 7% / 35%,
                url('../../images/bg/layer-3-right-bottom-xs.svg') bottom right 0 / 36%,
                url('../../images/bg/layer-3-right-top-xs.svg') top right / 48.5%,
                url('../../images/bg/layer-3-left-bottom-xs.svg') bottom left / 62.8%,
                url('../../images/bg/layer-2-left-top-xs.svg') top left / 35%,
                url('../../images/bg/layer-2-right-top-xs.svg') top right / 37%,
                url('../../images/bg/layer-2-left-bottom-xs.svg') bottom left / 54%,
                url('../../images/bg/layer-1-left-bottom-xs.svg') bottom 16.5% left / 49.2%,
                url('../../images/bg/layer-1-right-bottom-xs.svg') bottom right / 42%,
                url('../../images/bg/layer-1-left-top-xs.svg') top left / 50%,
                $whiteBackground;
    background-repeat: no-repeat;
  }

  &__content {
    flex: 1;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: minmax(384px, 50%);
    align-items: center;
    gap: 20px;
    box-sizing: border-box;
    margin-bottom: 4%;
    padding: 0 41px 0 41px;

    @media screen and (max-width: $md) {
      grid-template-rows: minmax(285px, 50%);
      padding: 0 15px 0 15px;
      margin-bottom: 4.4%;
      gap: 8px;
    }

    @media screen and (max-width: 950px) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, auto);
      gap: 34px 30px;
    }

    @media screen and (max-width: $sm) {
      padding: 0 60px 0 60px;
      margin-bottom: 4%;
    }

    @media screen and (max-width: 600px) {
      padding: 0 13px 0 13px;
    }

    @media screen and (max-width: $xs) {
      gap: 12px 20px;
      margin-bottom: 3%;
    }
  }

  &__title {
    @include text-format(500, 24px, normal);
    color: $fourth-game-text-primary;
    margin: 0.9% 0 3.2% 0;

    @media screen and (max-width: $md) {
      margin: -0.5% 0 0.88% 0;
    }

    @media screen and (max-width: $sm) {
      margin: -1% 0 4% 0;
    }

    @media screen and (max-width: $xs) {
      font-size: 16px;
      margin: -5% 0 5.1% 0;
    }
  }

  &__decoration {

    &_panda {
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translate(-25%, 29%);
      width: 24%;

      @media screen and (max-width: $md) {
        width: 27%;
        transform: translate(-24%, 33%);
      }

      @media screen and (max-width: $sm) {
        width: 43%;
        transform: translate(-26%, 28.5%);
      }

      @media screen and (max-width: $xs) {
        width: 49%;
        transform: translate(-23%, 29%);
      }
    }
  }

  &__close-btn {
    color: $fourth-game-primary;
    border-color: $fourth-game-primary;
    position: absolute;
    top: 5px;
    right: 5px;
  }

  &__progress-bar {
    border: 1px solid $fourth-game-primary;
  }

  &__counter {
    @include text-format(450, 20px, normal);
    color: $fourth-game-primary;

    @media screen and (max-width: $md) {
      font-size: 18px;
    }

    @media screen and (max-width: $sm) {
      font-size: 16px;
    }

    @media screen and (max-width: 380px) {
      font-size: 10px;
    }
  }

  &__step {
    border: 0.8px solid $fourth-game-primary;

    &:last-child {
      border-right: 0.8px solid $fourth-game-primary;
      border-radius: 0 6px 6px 0;
    }

    &.correct {
      background: lighten($fourth-game-correct, 15%);
    }
    &.incorrect {
      background: $fourth-game-incorrect;
    }
  }

  &__modal-close-btn {
    color: $fourth-game-primary;
  }

  &__game-btn {
    color: $fourth-game-text-primary;
    border: 1px solid $fourth-game-primary;

    &:disabled {
      color: $fourth-game-disabled;
      border-color: $fourth-game-disabled;
    }
  }

  &__game-btn-icon {
    width: 31px;
    height: 27px;
    top: -10px;
    left: -8px;

    @media screen and (max-width: 768px) {
      left: -7px;
      top: -11px;
    }

    @media screen and (max-width: 450px) {
      max-width: 15px;
      max-height: 13px;
      left: -4px;
      top: -6.5px;
    }
  }
}