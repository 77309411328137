@import '../../../../styles/sсss/mixins';
@import '../../../../styles/sсss/variables';

.results-cover {
  overflow: hidden;
  @include flex-center-column;
  box-sizing: border-box;
  width: calc(100% - 2*40px);
  min-height: 620px;
  padding-top: 50px;
  background-repeat: no-repeat;
  background-size: auto;
  position: relative;
  &_excellent {
    background-image:
            url('../../images/test-results-excellent-present-small.svg'),
            url('../../images/test-results-excellent-present.svg'),
            url('../../images/test-results-excellent-star.svg'),
            url('../../images/results-excellent-lightning.svg'),
            url('../../images/test-results-lamp-icon.svg');
    background-position: 65% 55%, 10% top, 98% 0, 18% 63%, bottom right;
    background-size: 100px, 100px, 100px, 100px, 300px;
  }
  &_good {
    &::before {
      z-index: -2;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 30px;
      right: 0;
      margin: auto;
      border: 2px solid #D4E779;
      border-radius: 60%;
      width: 85%;
      height: 90%;
      transform: rotate(-5deg);
    }
    &::after {
      z-index: -1;
      content: "";
      transform: rotate(-15deg);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 30px;
      right: 0;
      margin: auto;
      background-color: #fff;
      border-radius: 60%;
      width: 85%;
      height: 90%;
    }
    background-image:
            url('../../images/test-results-bad-stars.svg'),
            url('../../images/test-results-bad-letter.svg'),
            url('../../images/test-results-rocket-good.svg');
    background-position: 87% 0%, 23% 12%, 13% 110%;
    background-size: 7%, 5%, 16%;
  }
  &_bad {
    background-image:
            url('../../images/results-cover-bad-green-left.svg'),
            url('../../images/results-cover-bad-green-right.svg'),
            url('../../images/test-results-bad-stars.svg'),
            url('../../images/test-results-bad-letter.svg'),
            url('../../images/test-results-bad-rocket.svg');
    background-position: bottom left, top right, 8% 3%, 17% 7%, right 5% bottom 0;
    background-size: 25%, 25%, 7%, 5%, 20%;
  }
  &__buttons-list {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    max-width: 640px;
    margin: 0 auto;
    padding: 0;
  }
}

@media screen and (max-width: 1500px) {
  .results__cover {
    &_excellent {
      background-size: 7%, 8%, 12%, 8%, 32%;
    }

    &_good {
      background-image:
              url('../../images/test-results-bad-stars.svg'),
              url('../../images/test-results-bad-letter.svg'),
              url('../../images/test-results-rocket-good.svg'),
              url('../../images/test-results-lamp-icon.svg');
      background-size: 7%, 5%, 16%, 30%;
    }
  }
}

@media screen and (max-width: 1200px) {
  .results-cover {
    &_bad {
      background-position: bottom left, top right, 8% 3%, 17% 7%, right 5% bottom 12%;
    }
    &__buttons-list {
      width: 560px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .results-cover {
    &_excellent {
      background-position: 60% 47%, 10% top, 98% 0, 5% 63%, bottom -5% right;
    }
    &_bad {
      background-position: bottom left, top right, 8% 3%, 17% 7%, right -5% bottom 37%;
    }
  }
}

@media screen and (max-width: 900px) {
  .results-cover {
    padding-top: 70px;
    &_good {
      background-position: 87% 5%, 23% 12%, 13% 110%, right bottom 0%;
      &::before {
        width: 95%;
        height: 80%;
      }
      &::after {
        transform: rotate(-25deg);
        width: 95%;
        height: 80%;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .results-cover {
    overflow: hidden;
    padding-top: 80px;
    padding-bottom: 30px;
    min-height: fit-content;
    &__buttons-list {
      width: fit-content;
      flex-direction: column;
      gap: 20px;
    }
    &_excellent {
      background-image:
              url('../../images/test-results-excellent-present-small.svg'),
              url('../../images/test-results-excellent-present.svg'),
              url('../../images/test-results-excellent-star.svg'),
              url('../../images/results-excellent-lightning.svg');
      background-size: 11%, 8%, 12%, 8%;
      background-position: 90% 57%, 10% top, 98% 0, 5% 48%;
    }
    &_good {
      background-image:
              url('../../images/test-results-bad-stars.svg'),
              url('../../images/test-results-bad-letter.svg'),
              url('../../images/test-results-rocket-good.svg');
      background-position: 87% 5%, 23% 12%, 3% 80%;
      background-size: 9%, 7%, 20%;
      &::before {
        bottom: 20%;
      }
      &::after {
        transform: rotate(-20deg);
        bottom: 20%;
      }
    }
    &_bad {
      background-image: url('../../images/test-results-bad-stars.svg');
      background-position: 8% 3%;
      background-size: 8%;
    }
  }
}

@media screen and (max-width: 480px) {
  .results-cover {
    width: calc(100% - 2*20px);
    padding-top: 52px;
    &_excellent {
      background-image:
              url('../../images/test-results-excellent-present.svg'),
              url('../../images/test-results-excellent-star.svg');
      background-size: 13%, 20%;
      background-position: 10% top, 98% 0;
    }
    &_good {
      background-image:
              url('../../images/test-results-bad-stars.svg'),
              url('../../images/test-results-bad-letter.svg'),
              url('../../images/test-results-rocket-good.svg');
      background-position: 87% 5%, 23% 1%, 0 80%;
      background-size: 7%, 10%, 30%;
      &::before {
        bottom: 30%;
        height: 70%;
      }
      &::after {
        bottom: 30%;
        height: 70%;
        transform: rotate(-40deg);
      }
    }
    &_bad {
      background-position: 8% 3%, 87% 0, right -6% bottom;
      background-size: 12%, 12%, 30%;
    }
  }
}

@media screen and (max-width: 380px) {
  .results-cover {
    padding-top: 30px;
    &_good {
      &::before {
        transform: rotate(-15deg);
        width: 100%;
        height: 80%;
        left: -2%;
      }
      &::after {
        width: 100%;
        height: 80%;
      }
      background-position: 87% 5%, 23% 1%, 0 120%;
    }
    &_bad {
      background-position: 8% 3%, 87% 0, right -36% bottom;
      background-size: 12%, 12%, 40%;
    }
  }
}

