@import '../../../styles/sсss/mixins';
@import '../../../styles/sсss/variables';

.account-popup {
  @include flex-center;
  @include size (100%, 100%);
  background-image: url('../../../assets/images/background-1440px.svg');
  background-repeat: repeat-y;
  background-size: 100%;
  color: #000;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: 3;
  visibility: hidden;
  opacity: 0;
  transition: visibility .4s,opacity .4s ease-out;
  &_opened {
    visibility: visible;
    opacity: 1;
  }
  &__container {
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    padding: 53px 19px 118px;
    @include size (406px, 536px);
    border: 1px solid #000;
    border-radius: 10px;
    background-color: #fff;
    background-image: url('../images/profile-popup-panda.svg');
    @include backgroundImage;
    background-size: 150px auto;
    background-position-x: 240px;
    background-position-y: 310px;
    &::after {
      z-index: -1;
      content: "";
      position: absolute;
      top: 3px;
      left: 6px;
      background-color: #D4E779;
      @include size (406px, 536px);
      border-radius: 10px;
    }
  }
  &__name-text {
    margin: 0 0 60px 0;
    @include text-format (400, 24px, 74.2%);
  }
  &__menu {
    margin: 0 0 250px 0;
    padding: 0;
    list-style-type: none;
    &-item {
      display: flex;
      margin-bottom: 20px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    &-text {
      @include text-format (400, 24px, 24px);
      margin: 0;
    }
    &-button {
      @include text-format (400, 24px, 24px);
      border: none;
      background-color: #fff;
      padding: 0;
      cursor: pointer;
    }
    &-icon {
      @include size (19px, 20px);
      margin-right: 10px;
    }
  }
  &__exit-button {
    width: fit-content;
    @include text-format (400, 24px, 24px);
    border: none;
    background-color: #fff;
    padding: 0;
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
      transform: scale(1.1);
    }
  }
  &__button {
    transition: all 0.2s;
    &:hover {
      transform: scale(1.05);
    }
  }
}

.hidden {
  display: none;
}

@media screen and (max-width: 1024px) {
  .account-popup {
    background-image: url('../../../assets/images/background-1024px.svg');
  }
}

@media screen and (max-width: 768px) {
  .account-popup {
    background-image: url('../../../assets/images/background-768px.svg');
  }
}

@media screen and (max-width: 600px) {
  .account-popup {
    &__container {
      max-width: 90%;
      height: fit-content;
      background-size: 30%;
      background-position: bottom 10px right 10px;
      &::after {
        height: 100%;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .account-popup {
    background-image: url('../../../assets/images/background-320px.svg');
    &__container {
      padding-bottom: 50px;
    }
    &__menu {
      margin: 0 0 150px 0;
      &-text {
        @include text-format (400, 20px, 74.2%);
      }
      &-button {
        @include text-format (400, 20px, 74.2%);
      }
    }
    &__name-text {
      @include text-format (400, 20px, 74.2%);
    }
    &__exit-button {
      @include text-format (400, 20px, 74.2%);
    }
  }
}

@media screen and (max-width: 400px) {
  .account-popup {
    &__menu {
      margin: 0 0 100px 0;
      &-text {
        @include text-format (400, 18px, 74.2%);
      }
      &-button {
        @include text-format (400, 18px, 74.2%);
      }
    }
    &__name-text {
      @include text-format (400, 18px, 74.2%);
      margin: 0 0 30px 0;
    }
    &__exit-button {
      @include text-format (400, 18px, 74.2%);
    }
  }
}
