@import '../../../../styles/sсss/mixins.scss';
@import '../../../../styles/sсss/variables.scss';

.result-btn {
    &.result-btn:disabled {
        cursor: auto;
        color: #bdbaba;
        border: 1px solid #bdbaba;
        &:hover {
            transform: scale(1);
        }
    }
    cursor: pointer;
    position: relative;
    margin: 0 auto;
    @include size(209px, 46px);
    border-radius: 5px;
    background-color: #fff;
    outline: none;
    transition: transform 0.3s ease;
    @include text-format(500, 24px, normal);
    &-one {
        color: #1A1F00;
        border: 1px solid #445200;
    }
    &-two {
        color: #416371;
        border: 1px solid #5D95AC;
        @include text-format(500, 24px, normal);
    }
    &-three {
        color: #274870;
        border: 1px solid #719FD6;
    }
    &-four {
        color: $fourth-game-text-primary;
        border: 1px solid $fourth-game-primary;
    }
    &:hover {
        transform: scale(1.05);
    }
}
.decorative-img {
    position: absolute;
    height: 130%;
    left: -11px;
    bottom: 0;
    &-two {
        height: 75px;
        left: -35px;
    }
    &-three {
        height: auto;
        top: -14px;
        left: -15px;
    }

    &-four {
        width: 31px;
        height: auto;
        top: -10px;
        left: -5px;
    }
}

@media screen and (max-width: 767px) {
    .result-btn {
        @include size(188px, 43px);
        @include text-format(450, 20px, normal);
    }
    .decorative-img {
        &-two {
            height: 55px;
            left: -25px;
            bottom: 10px;
        }

        &-four {
            left: -7px;
            top: -11px;
        }
    }
}

@media screen and (max-width: 380px) {
    .result-btn {
        @include size(108px, 24px);
        @include text-format(450, 12px, normal);
    }
    .decorative-img {
        left: -5px;
        &-two {
            height: 29px;
            left: -15px;
            bottom: 5px;
        }

        &-four {
            width: 15px;
            left: -2px;
            top: -6.5px;
        }
    }
}
