@import '../../../../../styles/sсss/mixins.scss';
@import '../../../../../styles/sсss/variables.scss';

.popup {
    position: fixed;
    z-index: 1000;
    @include flex-center-column;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    transition: visibility .3s, opacity .3s linear;
    &_opened {
        visibility: visible;
        opacity: 1;
    }
    &__overlay {
        cursor: pointer;
        outline: none;
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        background-color: #ffffff;
        opacity: .5;
        z-index: -1;
    }
    &__container {
        position: relative;
        box-sizing: border-box;
        padding: 45px 52px;
        background-color: white;
        border-radius: 5px;
        @include size(401px, 197px);
        @include flex-space-between-column;
        background-image: url("../../images/stars.svg"), url("../../images/stars_rt.svg");
        background-repeat: no-repeat, no-repeat;
        background-position: left top, right bottom;
        
    }
    &__text {
        @include reset;
        @include text-format(400, 24px, normal);
        text-align: center;
        color: #1A1F00;
    }
    &__close {
        cursor: pointer;
        position: relative;
        border-radius: 5px;
        background: none;
        outline: none;
        @include reset;
        @include text-format(400, 24px, normal);
        @include size(150px, 35px);
        @include flex-center;
        margin: 0 auto;
        transition: transform 0.3s ease;
        
        &:hover {
            transform: scale(1.05);
        }
    }
}

@media screen and (max-width: 420px) {
    .popup {
        &__container {
            width: 90%;
        }
        &__text {
            @include text-format(400, 26px, normal);
        }
    }
}
