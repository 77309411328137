@import '../../../styles/sсss/mixins';
@import '../../../styles/sсss/variables';

.close-popup__button {
  @include reset;
  @include size(45px, 45px);
  border: 1px solid $borderColor;
  border-radius: 7px;
  background-image: url('../images/close.svg');
  @include backgroundImage;
  background-size: 15px;
  background-color: white;
  cursor: pointer;
  &_absolute {
    position: absolute;
    top: 50px;
    right: 50px;
  }
  &:hover {
    @include hoverScale;
  }
}

@media screen and (max-width: 1024px) {
  .close-popup__button {
    @include size(35px, 35px);
    &_absolute {
      top: 20px;
      right: 20px;
    }
  }
}

@media screen and (max-width: 500px) {
  .close-popup__button {
    @include size(32px, 32px);
    &_absolute {
      top: 10px;
      right: 10px;
    }
  }
}
