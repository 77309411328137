@import '../../../../styles/sсss/mixins';
@import '../../../../styles/sсss/variables';

.teachers {
  &__list {
    padding: 0;
    margin: 102px auto 208px;
    max-width: 970px;
    list-style-type: none;

    &-item {
      display: flex;
      gap: 55px;

      &:first-of-type {
        margin-bottom: 198px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .teachers {
    &__list {
      max-width: calc(100% - 2*75px);
      margin: 50px auto 165px;
    }
  }
}

@media screen and (max-width: 800px) {
  .teachers {
    &__list {
      max-width: 440px;
      margin: 110px auto 165px;
    }
  }
}

@media screen and (max-width: 500px) {
  .teachers {
    &__list {
      max-width: calc(100% - 2*20px);
      margin: 40px auto 35px;
    }
  }
}
