@import '../../../../styles/sсss/mixins';
@import '../../../../styles/sсss/variables';

.buy-practicum__cover {
  position: relative;
  min-width: 390px;
  max-width: 390px;
  height: 232px;
  border: 1px solid $greenText;
  border-radius: 26px;
  margin-bottom: 32px;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: right bottom;
  &_1-8 {
    background-image: url('../../images/teacher-photo(1-8).svg');
  }
  &_9-12 {
    background-image: url('../../images/teacher-photo(9-12).svg');
  }
  &_13-21 {
    background-image: url('../../images/teacher-photo(13-21).svg');
  }
  &_22-26 {
    background-image: url('../../images/teacher-photo(22-26).svg');
  }
  &-img {
    position: absolute;
    &_lamp {
      top: 6px;
      left: 6px;
    }
    &_star {
      top: 2px;
      left: 200px;
    }
    &_thumb-up {
      top: 130px;
      left: 202px;
      z-index: 2;
    }
  }
  &-title {
    @include text-format (500, 35px, 77.7%);
    background-image: url('../../images/practicum-cover-title.svg');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: top left;
    @include size (180px, 42px);
    transform: rotate(-5deg);
    margin: 76px 0 0 30px;
    padding-left: 5px;
  }
  &-subtitle {
    @include text-format (500, 30px, 77.7%);
    position: relative;
    transform: rotate(5deg);
    text-align: center;
    width: fit-content;
    margin: 20px 0 0 10px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center left;
    padding: 10px;
    @include size (230px, 40px);
    @include flex-center;
    box-sizing: border-box;
    &_1-8 {
      background-image: url('../../images/practicum-stroke-(1-8).svg');
      &-speech {
        transform: rotate(0deg);
        @include size (270px, 45px);
        padding: 0;
        margin: 20px 0 0 0;
      }
    }
    &_9-12 {
      background-image: url('../../images/practicum-cover-stroke(9-12).svg');
    }
    &_13-21 {
      background-image: url('../../images/practicum-stroke(13-21).svg');
    }
    &_22-26 {
      background-image: url('../../images/practicum-stroke(22-26).svg');
    }
    &::before {
      content: "";
      position: absolute;
      top: 15px;
      left: 88%;
      background-image: url('../../images/practicum-thumb-up.svg');
      @include backgroundImage;
      @include size (46px, 50px);
    }
  }
}

@media screen and (max-width: 500px) {
  .buy-practicum__cover {
    background-size: 45%;
    min-width: 100%;
    height: 215px;
    &-title {
      margin: 70px 0 0 10px;
      padding-top: 2px;
      background-size: contain;
      @include text-format (500, 24px, 77.7%);
      @include size (130px, 42px);
    }
    &-subtitle {
      margin: 5px 0 0 10px;
      @include text-format (500, 20px, 77.7%);
      @include size (160px, 40px);
    }
    &-img {
      &_lamp {
        width: 30px;
      }
      &_star {
        width: 55px;
        top: 2px;
        left: 140px;
      }
      &_thumb-up {
        top: 130px;
        left: 202px;
        z-index: 2;
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .buy-practicum__cover {
    height: 180px;
  }
}
