@import '../../../styles/sсss/mixins';
@import '../../../styles/sсss/variables';

.header {
  display: flex;
  justify-content: space-between;
  padding: 13px 41px;
  background-image: url('../images/Vector_2.svg');
  @include backgroundImage;
  background-size: auto;
  background-position: 79% 1%;
  border-bottom: 1px solid rgba(158, 184, 29, 0.48);
  &__overlay {
    top: 0;
    right: 0;
    position: fixed;
    width: 100%;
    height: 100vh;
    backdrop-filter: blur(8px);
    z-index: 2;
  }
  &__info {
    display: flex;
    align-items: center;
    &-logo {
      @include size(108px, 105px);
      background-image: url('../images/лого.svg');
      @include backgroundImage;
      cursor: pointer;
    }
    &-text {
      @include text-format(400, 24px, 26px);
      max-width: 198px;
      color: $greenText;
      margin-left: 16px;
    }
  }
  &__nav {
    display: flex;
    align-items: center;
    @include reset;
    margin-right: 10.4%;
    &-burger {
      box-sizing: border-box;
      background-color: white;
      background-image: url('../images/Burger-back.svg');
      background-repeat: no-repeat;
      background-size: 50%;
      background-position: 132px 505px;
      border-bottom: 1px solid $greenBackground;
      border-left: 1px solid $greenBackground;
      border-radius: 0px 0px 0px 24px;
      position: absolute;
      flex-direction: column;
      gap: 28px;
      padding: 250px 50px 90px;
      right: -100%;
      display: none;
      top: 0;
      z-index: 3;
      min-width: 259px;
      &_open {
        right: -1px;
        display: flex;
      }
      &_open .header__nav-item_active {
        border: 1px solid $greenText;
        border-radius: 15px;
      }
      &_open .header__nav-item:hover {
        border: 1px solid $greenText;
        border-radius: 15px;
      }
    }

    &_reset {
      margin: 0;
    }
    &-item {
      text-decoration: none;
      color: inherit;
      list-style: none;
      @include text-format(400, 21px, 14px);
      white-space: nowrap;
      margin-right: 15px;
      padding: 10px 5px;
      border: 1px solid transparent;
      cursor: pointer;
      &_active {
        border: 1px solid rgba(239, 124, 59, 1);
        border-radius: 21px;
      }
      &:last-of-type {
        margin: 0;
      }
      &:hover {
        border: 1px solid rgba(239, 124, 59, 1);
        border-radius: 21px;
      }
    }
  }
  &__auth {
    display: flex;
    align-items: center;
    &-search {
      transition: all 1s ease-in-out;
      &-button {
        @include reset;
        border: 1px solid $borderColor;
        border-radius: 10px;
        @include size(45px, 45px);
        background-image: url('../images/Seach.svg');
        @include backgroundImage;
        background-size: 29px;
        background-color: white;
        cursor: pointer;
        &:hover {
          @include hoverScale;
        }
        &_small {
          @include reset;
          border: none;
          background-image: url('../images/Seach.svg');
          @include backgroundImage;
          min-width: 18px;
          min-height: 18px;
          background-color: inherit;
          margin-left: 16px;
        }
      }
      &-area {
        border: 1px solid $borderColor;
        max-width: 282px;
        border-radius: 10px;
        display: flex;
        flex-wrap: nowrap;
        padding: 11px 0;
        margin-left: 21px;
        background: white;
        transition: all 1s ease-in-out;
        &_disable {
          display: none;
        }
      }
      &-input {
        @include reset;
        margin-left: 11px;
        padding-left: 21px;
        border: none;
        border-left: 1px solid $borderColor;
        @include text-format(400, 21px, 14px);
        max-height: 22px;
        transition: all 1s ease-in-out;
        &:focus {
          outline: 0;
          outline-offset: 0;
        }
      }
    }
    &-button {
      @include reset;
      border: 1px solid $borderColor;
      border-radius: 5px;
      min-width: 115px;
      background-color: inherit;
      color: black;
      @include text-format(400, 21px, 14px);
      padding: 8px 0;
      cursor: pointer;
      &:hover {
        @include hoverScale;
      }
    }
    &-link {
      @include linkStyle;
    }
    &-buttons {
      @include flex-center-column;
      gap: 10px;
      margin-left: 21px;
      &-burger {
        display: none;
        position: absolute;
        gap: 28px;
        top: 130px;
        right: -100%;
        z-index: 3;
        &_open {
          display: flex;
          right: 75px;
        }
      }
    }
    &-name {
      @include reset;
      @include text-format(400, 21px, 14px);
    }
    &-profile {
      &-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 40px;
        margin-left: 40px;
        &_small {
          margin-left: 20px;
        }
        &-burger {
          gap: 0;
          margin-left: 15px;
        }
      }
      &-button {
        @include reset;
        border: 1px solid $borderColor;
        border-radius: 23px;
        @include size(45px, 45px);
        background-image: url('../images/profile.svg');
        @include backgroundImage;
        background-size: 20px;
        background-color: inherit;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        &-burger {
          display: none;
          position: absolute;
          z-index: 3;
          right: -100%;
          top: 177px;
          &_open {
            display: block;
            right: calc((259px - 45px) / 2);
          }
        }
        &:hover {
          @include hoverScale;
        }
      }
    }
    &_full {
      width: 100%;
      justify-content: space-between;
    }
  }
  &__burger {
    @include size(45px, 45px);
    @include reset;
    background-color: inherit;
    border: none;
    position: relative;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 20px;
    cursor: pointer;
    &:focus-visible {
      outline: none;
    }
    &_open {
      position: absolute;
      z-index: 3;
      @include size(40px, 40px);
      top: 25px;
      right: 25px;
      border: 1px solid $borderColor;
      border-radius: 7px;
    }
    &-string {
      height: 3px;
      width: 100%;
      background: $greenText;
      margin: 0 auto;
      transition: 0.5s;
      &_open {
        height: 2px;
        width: 20px;
        &:first-of-type {
          position: absolute;
          transform: rotate(45deg);
          right: 8.5px;
          top: 18px;
          transition: 0.5s;
        }
        &:nth-of-type(2) {
          opacity: 0;
          transition: 0.5s;
        }
        &:last-of-type {
          position: absolute;
          transform: rotate(-45deg);
          top: 18px;
          right: 8.5px;
          transition: 0.5s;
        }
      }
    }
  }
  &_hiden {
    display: none;
  }
  &_visible {
    display: flex;
  }
}
@media screen and (max-width: 1280px) {
  .header {
    &__nav {
      margin-right: 2%;
      margin-left: 15px;
      &-burger {
        margin: 0;
      }
    }
  }
}
@media screen and (max-width: 930px) {
  .header {
    &__burger {
      display: flex;
    }
  }
}
@media screen and (max-width: 645px) {
  .header {
    padding: 11px 25px 3px;
    background-image: none;
    border: none;
    &__info {
      &-logo {
        @include size(2.8em, 2.7em);
      }
    }
    &__auth {
      &-buttons {
        &-burger {
          &_open {
            top: 85px;
            right: 65px;
          }
        }
      }
      &-profile {
        &-button {
          &-burger {
            top: 132px;
            right: 97px;
          }
        }
      }
      &-search {
        width: 100%;
        &-button {
          @include size(32px, 32px);
          background-size: 15px;
          &_small {
            min-width: 15px;
            min-height: 15px;
            margin-left: 10px;
          }
        }
        &-area {
          max-width: 100%;
          padding: 6px 0;
          margin-left: 0;
        }
        &-input {
          margin-left: 10px;
          padding-left: 10px;
          @include text-format(400, 16px, 8px);
          max-height: 16px;
        }
      }
    }
    &__nav {
      &-burger {
        background-position: 132px 490px;
        min-width: 239px;
        &_open {
          padding: 205px 40px 85px;
        }
      }
    }
    &__burger {
      min-width: 30px;
      @include size(30px, 30px);
      margin-left: 15px;
      &-string {
        height: 1px;
        &_open {
          width: 10px;
          &:first-of-type {
            right: 8.5px;
            top: 13.5px;
          }
          &:last-of-type {
            top: 13.5px;
            right: 8.5px;
          }
        }
      }
    }
  }
}
