@import '../../../../styles/sсss/mixins';
@import '../../../../styles/sсss/variables';

.test-results__button {
  position: relative;
  @include size (242px, 60px);
  @include text-format(500, 24px, .95);
  @include backgroundImage;
  background-color: #fff;
  background-image: url('../../images/results-button.svg');
  border: .87px solid #000;
  border-radius: 14.85px;
  cursor: pointer;
  transition: transform .5s;
  &_return::after {
    content: "";
    position: absolute;
    top: -50px;
    left: 200px;
    background-image: url('../../images/return-btn-panda.svg');
    @include backgroundImage;
    @include size (54px, 86px);
  }
  &:hover {
    transform: scale(1.1);
  }
  &_save {
    margin: 0 auto;
  }
  &_share-vk {
    &:hover {
      transform: scale(1);
    }
    &::before {
      opacity: 0;
      box-sizing: border-box;
      content: "Скоро эта кнопка будет доступна, ты сможешь поделиться своим результатом";
      @include text-format (500, 16px, .95);
      padding: 11px 34px 0 19px;
      position: absolute;
      top: -100px;
      left: 10px;
      background-image: url('../../images/return-btn-panda.svg'), url('../../images/VK-popup.svg');
      background-repeat: no-repeat no-repeat;
      background-position: 225px, center;
      background-size: 26px, auto;
      @include size (260px, 92px);
      transition: opacity .5s;
    }
    &:hover::before {
      opacity: 1;
    }
  }
}

.result-button__link {
  @include linkStyle;
}

@media screen and (max-width: 1200px) {
  .test-results__button {
    @include size (232px, 56px);
  }
}

@media screen and (max-width: 800px) {
  .test-results__button {
    @include size (200px, 50px);
    @include text-format(500, 20px, .95);
    &_return::after {
      top: -45px;
      left: 165px;
      @include size (44px, 76px);
    }
  }
}

@media screen and (max-width: 480px) {
  .test-results__button {
    @include size (176px, 45px);
    @include text-format(500, 18px, 14.36px);
    &_return::after {
      left: 150px;
      top: -12px;
      @include size (32px, 29px);
    }
  }
}

@media screen and (max-width: 400px) {
  .test-results__button {
    &_share-vk {
      &::before {
        left: -45px;
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .test-results__button {
    @include size (156px, 38px);
    @include text-format(500, 16px, 14.36px);
    &_return::after {
      top: -18px;
      left: 130px;
    }
  }
}
