@import url('../assets/normalize.css');
@import url('../assets/fonts/fonts.css');

html,
body {
  font-family: 'Futura PT', sans-serif;
}

[data-no-scroll] {
  overflow: hidden;
}