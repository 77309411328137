@import '../../../../../../styles/sсss/mixins';
@import '../../../../../../styles/sсss/variables';

.progress-bar {
  &__steps {
    @include reset;
    position: relative;
    list-style: none;
    @include flex-space-between;
    box-sizing: border-box;
    height: 13px;
  }
  &__progress {
    @include size(76px, 53px);
    position: absolute;
    bottom: 0;
  }
}

.hero {
  &--three {
    @include size(114px, 80px);
    offset-path: path('M56.2,22.8c2.9-1.7,39.7-22,70.5-4c17.2,10.1,23.6,26.9,27.4,36.9c11,28.9,11.8,73.2-15.7,104.6 c-36.2,41.2-106.6,43.3-120,22.2c-7.4-11.6,3-29.6,3.7-30.8c24-40.4,119.3-49.7,168-9.2c26.2,21.8,21.8,43.8,54.8,62.8 c29.3,16.9,61.8,16.2,68.3,16c33.2-1.2,47.7-14.7,80.3-9.5c14,2.2,25,6.9,32,10.5');
    animation: rocketFlight 5s ease-in;
  }
}

@media screen and (max-width: 1149px) {
  .progress-bar {
    &__progress {
      @include size(56px, 43px);
      background-size: 100%;
      background-repeat: no-repeat;
      left: -20%;
    }
  }
}

@media screen and (max-width: 767px) {
  .progress-bar {
    &__progress {
      @include size(46px, 33px);
    }
    &__steps {
      height: 10px;
    }
  }
}

@media screen and (max-width: 350px) {
  .progress-bar {
    &__steps {
      height: 6px;
    }
  }
}

@keyframes rocketFlight {
  100% {
    scale: 3;
    offset-distance: 100%;
  }
}