@import '../../../../styles/sсss/mixins';
@import '../../../../styles/sсss/variables';

.quiz {
  width: 680px;
  min-height: 340px;
  box-sizing: border-box;
  position: relative;
  margin: 0 auto;
  padding: 45px 26px 105px 38px;
  border: 2px solid $greenText;
  border-radius: 5px;
  background-color: #fff;
  &__title {
    margin: 0 0 30px 0px;
    text-align: justify;
    @include text-format(600, 24px, auto);
  }
  &__buttons-area {
    display: flex;
    width: 290px;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    right: 25px;
    bottom: 15px;
  }
}

.background-1 {
  background-image: url('../../images/background-test-1.svg');
  background-repeat: no-repeat;
  background-size: auto;
  background-position: top right;

}

.background-2 {
  background-image: url('../../images/background-test-2.svg');
  background-repeat: no-repeat;
  background-size: auto;
  background-position: top right;
}

.background-3 {
  background-image: url('../../images/background-test-3.svg');
  background-repeat: no-repeat;
  background-size: auto;
  background-position: top right;
}

.background-4 {
  background-image: url('../../images/background-test-4.svg');
  background-repeat: no-repeat;
  background-size: auto;
  background-position-x: 80%;
  background-position-y: 25%;
}

.background-5 {
  background-image: url('../../images/background-test-5.svg');
  background-repeat: no-repeat;
  background-size: auto;
  background-position: top - 45px right -26px;
}

.background-6 {
  background-image: url('../../images/background-test-6.svg');
  background-repeat: no-repeat;
  background-size: auto;
  background-position: top right;
}

@media screen and (max-width: 1100px) {
  .quiz {
    max-width: 85%;
    &__title {
      @include text-format (600, 20px, 1.28);
    }
  }
}

@media screen and (max-width: 700px) {
  .background-1, .background-2, .background-3, .background-4, .background-5, .background-6 {
    background: #fff none;
  }
  .quiz {
    max-width: 95%;
    padding: 35px 20px 25px;
    &__title {
      @include text-format(600, 18px, 1.28);
    }

    &__buttons-area {
      margin-top: 70px;
      position: static;
      width: 100%;
      justify-content: center;
    }
  }
}