@import '../../../../styles/sсss/mixins';
@import '../../../../styles/sсss/variables';

.buy-practicum__description {
  box-sizing: border-box;
  max-width: 921px;
  height: fit-content;
  border: 1.5px solid #9EBB13;
  border-radius: 15px;
  padding: 100px 36px;
  background-position-x: 98%;
  background-position-y: 2%;
  background-repeat: no-repeat;
  background-size: auto;
  &_lamps {
    background-image: url('../../images/practicum-description-lamps.svg');
  }
  &_books {
    background-image: url('../../images/practicum-description-books.svg');
  }
  &_letters {
    background-image: url('../../images/practicum-description-letters.svg');
  }
  &_punctuation {
    background-image: url('../../images/practicum-description-punctuation.svg');
  }
  &-title {
    box-sizing: border-box;
    padding-left: 10px;
    margin: 0 0 10px 0;
    @include text-format (500, 24px, 1.28);
    @include size (385px, 43px);
    background-position: top;
    background-repeat: no-repeat;
    background-size: auto;
    background-image: url('../../images/practicum-description-title.svg');
    &-second {
      margin: 60px 0 15px 0;
      @include size (270px, 43px);
      background-image: url('../../images/practicum-description-title-second.svg');
    }
  }
  &-paragraph {
    @include text-format (500, 24px, 1.2);
    margin: 0 0 0 12px;
    &-last {
      margin: 40px 0 0 12px;
      background-repeat: no-repeat;
      background-position: top;
      background-size: cover;
    }
    &_highlighted {
      @include size (500px, fit-content);
      background-image: url('../../images/practicum-description-green-back-1.svg');
      &-second {
        @include size (169px, fit-content);
        background-image: url('../../images/practicum-description-back-2.svg');
      }
    }
  }
  &-list {
    padding: 0;
    list-style-type: none;
    margin: 0 0 60px 12px;
  }
  &-item {
    @include text-format (500, 24px, 1.28);
    margin: 0 0 8px 0;
  }
  &-cover-915px {
    display: none;
  }
  &-button-scroll {
    cursor: pointer;
    display: block;
    box-sizing: border-box;
    width: 95%;
    padding: 15px 10px;
    box-shadow: 0px 2px 10px 2px rgba(154, 50, 76, 0.2);
    background-color: #fff;
    margin: 0 auto 30px;
    @include text-format (500, 22px, 18px);
    border: 1.5px solid #DC3962;
    border-radius: 15px;
    transition: transform .5s;
    display: none;
    &:hover {
      transform: scale(1.02);
    }
  }
}

@media screen and (max-width: 1120px) {
  .buy-practicum__description {
    padding: 50px 26px;
    &_lamps {
      background-image: none;
    }
    &_books {
      background-image: none;
    }
    &_letters {
      background-image: none;
    }
    &_punctuation {
      background-image: none;
    }
    &-list {
      margin: 0 0 30px 12px;
    }
    &-item {
      @include text-format (500, 22px, 1.2);
      margin: 0 0 5px 0;
    }
    &-paragraph {
      @include text-format (500, 22px, 1.2);
      margin: 0 0 0 12px;
      &-last {
        margin: 20px 0 0 12px;
      }
    }
    &-title {
      @include text-format (500, 22px, 1.2);
      padding: 5px 0 0 10px;
      &-second {
        margin: 40px 0 15px 0;
      }
    }
  }
}

@media screen and (max-width: 915px) {
  .buy-practicum__description {
    padding: 25px 26px;
    &-cover-915px {
      display: block;
    }
    &_lamps {
      background-image: url('../../images/practicum-description-lamps.svg');
    }
    &_books {
      background-image: url('../../images/practicum-description-books.svg');
    }
    &_letters {
      background-image: url('../../images/practicum-description-letters.svg');
    }
    &_punctuation {
      background-image: url('../../images/practicum-description-punctuation.svg');
    }
  }
}

@media screen and (max-width: 685px) {
  .buy-practicum__description {
    background: none;
  }
}

@media screen and (max-width: 520px) {
  .buy-practicum__description {
    &-cover-915px {
      width: fit-content;
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 500px) {
  .buy-practicum__description {
    &-title {
      max-width: 100%;
      background-size: contain;
    }
    &-cover-915px {
      width: 95%;
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 450px) {
  .buy-practicum__description {
    &-title {
      @include text-format (500, 20px, 1.25);
    }
    &-paragraph {
      @include text-format (500, 20px, 1.25);
    }
    &-item {
      @include text-format (500, 20px, 1.25);
    }
  }
}

@media screen and (max-width: 450px) {
  .buy-practicum__description {
    padding: 12px 10px 20px 5px;
    &-button-scroll {
      display: block;
      @include text-format (500, 20px, 20px);
    }
    &-title {
      @include size (380px, 33px);
      @include text-format (500, 20px, 22px);
      &-second {
        @include size (230px, 33px);
        margin: 20px 0 15px 0;
      }
    }
    &-paragraph {
      text-align: justify;
      @include text-format (500, 20px, 24px);
    }
    &-item {
      @include text-format (500, 20px, 24px);
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 377px) {
  .buy-practicum__description {
    &-title {
      @include size (280px, 23px);
      @include text-format (500, 16px, 20px);
      &-second {
        @include size (200px, 28px);
        margin: 20px 0 15px 0;
      }
    }
    &-paragraph {
      text-align: justify;
      @include text-format (500, 16px, 20px);
    }
    &-item {
      @include text-format (500, 16px, 20px);
    }
    &-button-scroll {
      padding: 9px 0;
      background-color: #fff;
      @include text-format (500, 18px, 18px);
    }
  }
}
