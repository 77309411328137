@import '../../../../styles/sсss/mixins';
@import '../../../../styles/sсss/variables';

.test-button-back {
  padding: 0 0 0 17px;
  margin-right: 10px;
  @include size(127px, 36px);
  border: 2px solid $greenText;
  color: #000;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-image: url('../../images/testbutton-arrow-back.svg');
  background-position: 18%;
  background-color: #fff;
  @include text-format(500, 21px, auto);
  text-align: center;
  cursor: pointer;
  transition: transform .5s;
  &:hover {
    transform: scale(1.1);
  }
}

@media screen and (max-width: 500px) {
  .test-button-back {
    @include size(100px, 36px);
    @include text-format(500, 18px, auto);
    background: none;
    text-align: center;
    padding: 0;
    margin-right: 20px;
  }
}
