@import '../../../../../../styles/sсss/variables.scss';
@import '../../../../../../styles/sсss/mixins.scss';

.fourth-game-modal {
  @include flex-center-column;
  position: relative;
  background: url('../images/bg/right-bottom.svg') bottom right,
              url('../images/bg/left-top.svg') top left,
              $whiteBackground;
  background-repeat: no-repeat;
  border: 2px solid $fourth-game-primary;
  border-radius: 5px;
  justify-content: center;
  padding: 20px;
  gap: 30px;
  min-height: 200px;
  width: 400px;
  max-width: 100%;
  box-sizing: border-box;
  color: $fourth-game-text-primary;

  &__title {
    @include reset;
    @include text-format(500, 24px, 1.28);

    @media screen and (max-width: 400px) {
      font-size: 20px;
    }
  }

  &__btn {
    border: 1px solid $fourth-game-primary;
  }

  &__list {
    @include reset;
    @include text-format(500, 18px, normal);
    align-self: flex-start;
    list-style: none;

    @media screen and (max-width: 400px) {
      font-size: 16px;
    }
  }
}