@import '../../../../styles/sсss/mixins';
@import '../../../../styles/sсss/variables';

.buy-practicum {
  width: 100vw;
  height: fit-content;
  background-color: #fff;
  padding-bottom: 71px;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 37px 45px 22px;
  }
  &__logo {
    @include size (108px, 105px);
  }
  &__title {
    @include flex-center;
    @include text-format (500, 35px, 77.7%);
    @include size (416px, 54px);
    margin: 0;
    background-image: url('../../images/background-title-practicum.svg');
    @include backgroundImage;
    &-520px {
      display: none;
      flex-direction: column;
      align-items: center;
    }
  }
  &__content {
    display: flex;
    justify-content: center;
    gap: 35px;
    margin: 0 46px 35px;
  }
  &__info {
    @include flex-center-column;
    box-sizing: border-box;
    padding: 35px 25px;
    max-width: 391px;
    max-height: 503px;
    border: 1px solid #F6D491;
    border-radius: 26px;
    margin: 0;
    &-title {
      margin: 0 0 35px 0;
      @include text-format (500, 24px, 1.28);
      @include size (169px, 35px);
      @include flex-center;
      background-image: url('../../images/background-practicum-info.svg');
      @include backgroundImage;
    }
    &-list {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
    &-item {
      display: flex;
      align-items: flex-start;
      margin: 0 0 35px 0;
      &:last-of-type {
        margin: 0;
      }
    }
    &-icon {
      @include size (45px, 43px);
      margin-right: 14px;
    }
    &-text {
      margin: 5px 0 0 0;
      width: 100%;
      @include text-format (500, 22px, 1.28);
      &_highlighted {
        display: inline-block;
        background-image: url('../../images/background-practicum-price.svg');
        background-repeat: no-repeat;
        background-position: top;
        background-size: contain;
        @include size (120px, 36px);
        text-align: center;
      }
    }
  }
  &__link {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    display: block;
    box-sizing: border-box;
    padding: 12px 55px;
    @include size (339px, 79px);
    @include text-format (500, 30px, 0.9);
    border: 3px solid #DC3962;
    color: #000;
    border-radius: 20px;
    box-shadow: 0px 2px 10px 2px rgba(154, 50, 76, 0.2);
    background-color: #fff;
    margin: 0 auto;
    transition: transform .5s;
    &:hover {
      transform: scale(1.1);
    }
  }
  &__support {
    box-sizing: border-box;
    padding: 20px;
    margin: 20px 0 0;
    max-width: 391px;
    border: 1px solid $greenText;
    border-radius: 26px;
    @include text-format (500, 22px, 1.28);
  }
}

@media screen and (max-width: 1024px) {
  .buy-practicum {
    &__logo {
      @include size (88px, 85px);
    }
    &__title {
      @include text-format (500, 32px, 75%);
      @include size (380px, 54px);
    }
    &__info {
      &-title {
        margin: 0 0 35px 0;
        @include text-format (500, 22px, 1.2);
        @include size (169px, 35px);
      }
      &-item {
        margin: 0 0 25px 0;
      }
      &-text {
        @include text-format(500, 22px, 1.2);
      }
    }
    &__link {
      padding: 12px 45px;
      @include size (320px, 79px);
      @include text-format (500, 26px, 0.9);
    }
    &__header {
      margin: 37px 25px 22px;
    }
    &__content {
      margin: 0 25px 35px;
    }
  }
}

@media screen and (max-width: 915px) {
  .buy-practicum {
    &__content {
      gap: 20px;
      flex-direction: column;
    }
    &-cover {
      display: none;
    }
    &__info {
      max-width: 100%;
    }
    &__support {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 685px) {
  .buy-practicum {
    &__title {
      @include text-format (500, 30px, 75%);
      @include size (320px, 54px);
    }
    &__link {
      padding: 12px 25px;
      @include size (220px, 79px);
      @include text-format (500, 22px, 0.9);
    }
  }
}

@media screen and (max-width: 520px) {
  .buy-practicum {
    &__title {
      @include size (170px, 34px);
      &_second {
        background-image: url('../../images/practicum-title-background-2.svg');
        @include size (200px, 34px);
      }
      &_hidden {
        display: none;
      }
      &-520px {
        display: flex;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .buy-practicum {
    &__logo {
      @include size (68px, 65px);
    }
    &__info {
      &-title {
        @include text-format (500, 20px, 1.25);
      }
      &-text {
        @include text-format(500, 20px, 1.52);
      }
      &-item {
        margin: 0 0 15px 0;
      }
    }
    &__support {
      @include text-format (500, 20px, 1.25);
    }
    &__link {
      padding: 10px 50px;
      @include size (250px, 59px);
      @include text-format (500, 20px, 20px);
      border: 1.5px solid #DC3962;
      border-radius: 15px;
    }
  }
}

@media screen and (max-width: 377px) {
  .buy-practicum {
    &__header {
      margin: 37px 15px 22px;
    }
    &__logo {
      @include size (45px, 42px);
    }
    &__title {
      @include text-format (500, 20px, 1);
      @include size (140px, 26px);
      &_second {
        background-image: url('../../images/practicum-title-background-2.svg');
        @include size (160px, 26px);
      }
      &_hidden {
        display: none;
      }
      &-520px {
        display: flex;
      }
    }
    &__content {
      margin: 0 15px 35px;
      width: calc(100% - 2*15px);
    }
    &__support {
      @include text-format (500, 16px, 23px);
      padding: 15px;
    }
    &__info {
      padding: 20px 15px;
      &-title {
        @include text-format (500, 18px, 23px);
      }
      &-text {
        @include text-format(500, 16px, 1.52);
      }
      &-item {
        margin: 0 0 10px 0;
      }
      &-icon {
        @include size (32px, 32px);
        margin-right: 10px;
      }
      &-text {
        margin: 0;
        &_highlighted {
          @include size (100px, 36px);
        }
      }
    }
  }
}
