@import '../../../../../styles/sсss/mixins.scss';
@import '../../../../../styles/sсss/variables.scss';

.game-button {
  cursor: pointer;
  position: relative;
  margin: 0 auto;
  border-radius: 5px;
  background-color: #fff;
  outline: none;
  transition: transform 0.3s ease;
  box-sizing: border-box;

  &[data-size="md"] {
    @include size(209px, 46px);
    @include text-format(500, 24px, normal);
  }

  &[data-size="sm"] {
    @include size(150px, 35px);
    @include text-format(500, 18px, normal);
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:not(:disabled):hover {
    transform: scale(1.05);
  }

  &__img {
    position: absolute;
  }

  @media screen and (max-width: 768px) {
    &[data-size="md"] {
      @include size(188px, 43px);
      @include text-format(450, 20px, normal);
    }
  }

  @media screen and (max-width: 450px) {
    &[data-size="md"] {
      @include size(108px, 24px);
      @include text-format(450, 12px, normal);
    }
  }
}
