@mixin text-format($font-weight, $font-size, $line-height) {
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
}

@mixin size($width, $height) {
  height: $height;
  width: $width;
}

@mixin reset {
  margin: 0;
  padding: 0;
}

@mixin backgroundImage {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-center-column {
  display: flex;
  align-items: center;
  flex-direction: column;
}

@mixin flex-space-between {
  display: flex;
  justify-content: space-between;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-space-between-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@mixin psevdoElement {
  content: '';
  display: block;
}

@mixin hoverScale {
  transition: all 0.2s ease-in-out;
  transform: scale(1.2);
}

@mixin linkStyle {
  text-decoration: none;
  color: #000;
}
