@import '../../../styles/sсss/mixins';
@import '../../../styles/sсss/variables';

.multiple-answer {
  &__container {
    display: block;
    width: fit-content;
    vertical-align: bottom;
    position: relative;
    margin: 0 0 15px 0;
    cursor: pointer;
    font-size: 13px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  &__mark {
    box-sizing: border-box;
    position: absolute;
    top: 5px;
    left: 0;
    @include size (25px, 25px);
    border-radius: 2px;
    background-color: transparent;
    border: 1.5px solid $grey;
    &:after {
      box-sizing: border-box;
      content: "";
      position: absolute;
      display: block;
      top: 2.5px;
      left: 2.5px;
      @include size (17px, 17px);
      border-radius: 2px;
      background-color: #fff;
      border: 1px solid $grey;
    }
  }
  &__result-list {
    list-style-type: none;
    padding: 0;
    margin-top: 37px;
  }
  &__result-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    min-height: 25px;
    gap: 10px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.multiple-answer__input:checked ~ .multiple-answer__mark:after {
  background-color: $yellow;
  border: yellow;
  left: 2.5px;
}