@import '../../../styles/sсss/mixins';
@import '../../../styles/sсss/variables';

.back-button {
    position: absolute;
    top: 20px;
    left: 40px;
    @include size (63px, 63px);
    border: none;
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff;
    border-radius: 5px;
    background-image: url('../../../pages/TestResults/images/results-arrow-back.svg');
    &:hover {
      background-image: url('../../../pages/TestResults/images/result-arrow-back-hovered.svg');
      background-color: $greenText;
    }
}

@media screen and (max-width: 500px) {
    .back-button {
        @include size (33px, 33px);
        background-size: 90%;
    }
}
