@import '../../../../../../styles/sсss/variables.scss';
@import '../../../../../../styles/sсss/mixins.scss';

.fourth-game-game-over-modal {
  @include flex-center-column;
  position: relative;
  background: url('../images/bg/right-bottom.svg') bottom right,
              url('../images/bg/left-top.svg') top left,
              $whiteBackground;
  background-repeat: no-repeat;
  border: 2px solid $fourth-game-primary;
  border-radius: 5px;
  height: 198px;
  width: 401px;
  max-width: 100%;
  justify-content: center;
  gap: 35px;
  padding: 42px 10px 20px 10px;
  box-sizing: border-box;

  &__title,
  &__subtitle {
    @include reset;
    color: $fourth-game-text-primary;
  }

  &__title {
    @include text-format(500, 32px, 1.28);

    @media screen and (max-width: 450px) {
      font-size: 28px;
    }

    @media screen and (max-width: 400px) {
      font-size: 22px;
    }
  }

  &__subtitle {
    @include text-format(500, 24px, 1.29);
    border: 1px solid $fourth-game-primary;
    border-radius: 4px;
    padding: 0 10px;

    @media screen and (max-width: 450px) {
      font-size: 20px;
    }

    @media screen and (max-width: 400px) {
      font-size: 16px;
    }
    
  }

  &__decoration {
    position: absolute;
    right: 0;
    transform: translate(40%, -20%);
  }
}