@import '../../../../styles/sсss/mixins';
@import '../../../../styles/sсss/variables';

.tests {
  &__content {
    position: relative;
    @include flex-center-column;
    flex-grow: 1;
    background-image: url('../../../../assets/images/background-1440px.svg');
    background-size: contain;
    background-repeat: repeat;
    &-title {
      margin: 100px auto 0;
    }
  }
}