@import '../../../styles/sсss/mixins';
@import '../../../styles/sсss/variables';

.loader {
  width: 70%;
  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
  }
}

@media screen and (max-width: 800px) {
  .loader {
    width: 100%;
  }
}
