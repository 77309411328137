@import '../../../../styles/sсss/mixins';
@import '../../../../styles/sсss/variables';

.test-button {
  position: relative;
  padding: 0 0 0 17px;
  @include size(127px, 36px);
  border: 2px solid $greenText;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-image: url('../../images/arrow-test-svg.svg');
  background-position: 83%;
  background-color: #fff;
  @include text-format(500, 21px, auto);
  text-align: left;
  cursor: pointer;
  transition: transform .5s;
  &:hover {
    transform: scale(1.1);
  }
  &_final {
    width: 166px;
    background-image: none;
    padding: 0;
    text-align: center;
    &::before {
      content: "";
      position: absolute;
      top: -100px;
      left: 25%;
      background-image: url('../../images/panda-btn-final.svg');
      @include backgroundImage;
      @include size (84px, 106px);
    }
  }
}

.test-button__panda::after {
  content: "";
  position: absolute;
  top: -42px;
  left: 0;
  background-image: url('../../images/panda-btn.svg');
  @include backgroundImage;
  @include size (123px, 46px);
}

.disabled {
  border-color: rgba(214, 213, 213, 1);
  background-image: url('../../images/arrow-test-svg_disabled.svg');
  cursor: auto;
  &:hover {
    transform: scale(1);
  }
}

@media screen and (max-width: 500px) {
  .test-button {
    @include size(100px, 36px);
    @include text-format(500, 18px, auto);
    background: none;
    text-align: center;
    color: #000;
    padding: 0;
    &__link {
      @include linkStyle;
    }
    &__panda::after {
      @include size(100px, 46px);
    }
    &_final {
      height: fit-content;
      &::before {
        display: none;
      }
    }
  }
}
