@import '../../../styles/sсss/mixins';
@import '../../../styles/sсss/variables';

.course-card {
  position: relative;
  @include size (520px, 272px);
  @include flex-center-column;
  justify-content: center;
  border: 1px solid #000;
  border-radius: 26px;
  cursor: pointer;
  transition: all 0.2s linear;
  &:first-of-type {
    &::after {
      content: "";
      position: absolute;
      top: -230px;
      left: 0;
      @include size (443px, 261px);
      background-image: url('../../../pages/ProfileCourses/images/account-panda-list.svg');
      background-position: -20px left;
      background-repeat: no-repeat;
    }
  }
  &:hover {
    transform: scale(1.05);
  }
  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top:11px;
    left: 13px;
    border: 1px solid $greenText;
    border-radius: 26px;
    @include size (520px, 272px);
  }
  &__title {
    margin: 0;
    @include text-format (500, 40px, 77.7%);
  }
  &__subtitle {
    margin: 30px 0 0;
    @include text-format (500, 22px, 77.7%);
  }
  &_disabled {
    cursor: not-allowed;
  }
}

@media screen and (max-width: 1200px) {
  .course-card {
    align-self: center;
    justify-self: center;
  }
}

@media screen and (max-width: 700px) {
  .course-card {
    width: 100%;
    height: 220px;
    &::before {
      width: 100%;
      height: 100%;
    }
  }
}

@media screen and (max-width: 600px) {
  .course-card {
    &__title {
      @include text-format (500, 32px, 77.7%);
    }
    &__subtitle {
      margin: 20px 0 0;
      @include text-format (500, 18px, 77.7%);
    }
    &:first-of-type {
      &::after {
        @include size (350px, 221px);
        background-size: contain;
        background-position: -20px left;
        top: -180px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .course-card {
    &__title {
      @include text-format (500, 28px, 77.7%);
    }
    &:first-of-type {
      &::after {
        @include size (285px, 201px);
        top: -150px;
      }
    }
  }
}
