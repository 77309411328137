@import '../../../../styles/sсss/mixins.scss';
@import '../../../../styles/sсss/variables.scss';

.correct-answers {
  flex-grow: 3;
  @include flex-center;
  &-inner {
    @include text-format(500, 28px, normal);
    margin: 7px auto 8px;
    @include size(423px, 50px);
    background-color: #fff;
    border-radius: 5px;
    @include flex-center;
    color: #1A1F00;
    max-height: 50px;
    &::before,
    &::after {
      content: "";
      position: absolute;
      z-index: 10;
      background-size: cover;
      background-repeat: no-repeat;
    }
    &-one {
      position: relative;
      border: 1px solid #91CA09;
      box-shadow: 0px 4px 4px 0px rgba(158, 184, 29, 0.25);
      &::before {
        @include size(29px, 34px);
        background-image: url('../../../../assets/images/leaves-first-game-1.svg');
        left: -2px;
        top: 0;
      }

      &::after {
        @include size(27px, 34px);
        background-image: url('../../../../assets/images/leaves-first-game-2.svg');
        right: -2px;
        bottom: 0;
      }

    }
    &-two {
      position: relative;
      border: 1px solid #339E8B;
      box-shadow: 0px 4px 4px 0px rgba(100, 133, 248, 0.25);

      &::before {
        @include size(60px, 60px);
        background-image: url('../../../../assets/images/left-img-two.svg');
        left: -30px;
        top: -25px;
      }

      &::after {
        @include size(60px, 60px);
        background-image: url('../../../../assets/images/right-img-two.svg');
        right: -20px;
        bottom: -2px;
      }
    }
  }
  &-one {

  }
  &-two {
    flex-grow: 2;
  }
}

@media screen and (max-width: 768px) {
  .correct-answers {
    &-inner {
      width: 100%;
      @include text-format(400, 22px, normal);
    }
  }
}

@media screen and (max-width: 520px) {
  .correct-answers {
    &-inner {
      height: 40px;
      @include text-format(400, 16px, normal);
      &-one {
        &::before {
          @include size(20px, 20px);
          left: 0;
        }
        &::after {
          @include size(20px, 25px);
          right: 0;
        }
      }
      &-two {
        &::before {
          @include size(40px, 40px);
          left: -15px;
          top: -15px;
        }
        &::after {
          @include size(40px, 40px);
          right: -10px;
          bottom: -2px;
        }
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .correct-answers {
    &-inner {
      height: 30px;
      &-two {
        &::before {
          @include size(40px, 40px);
          left: -15px;
          top: -15px;
        }

        &::after {
          @include size(40px, 40px);
          right: -10px;
          bottom: -2px;
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .correct-answers {
    &-inner {
      height: 20px;
      &-two {
        &::before {
          @include size(40px, 40px);
          left: -15px;
          top: -15px;
        }
        &::after {
          @include size(40px, 40px);
          right: -10px;
          bottom: -2px;
        }
      }
    }
  }
}