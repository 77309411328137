@import '../../../../styles/sсss/mixins';
@import '../../../../styles/sсss/variables';

.ege {
  &__description {
    margin: 0 41px 10px;
    @include text-format(500, 21px, 108.7%);
    max-width: 800px;
    text-align: left;
    &-auth {
      margin: 0 41px 49px;
      @include text-format(500, 21px, 108.7%);
      max-width: 800px;
      text-align: left;
    }
  }
}
@media screen and (max-width: 768px) {
  .ege {
    &__subtitle {
      margin: 0 50px 24px;
      text-align: center;
    }
    &__description {
      margin: 0 50px 25px;
      @include text-format(500, 18px, 88%);
      text-align: center;
      &-auth {
        margin: 0 50px 25px;
        @include text-format(500, 18px, 88%);
        text-align: center;
      }
    }
  }
}
@media screen and (max-width: 645px) {
  .ege {
    &__title {
      @include text-format(500, 30px, 90%);
      margin-bottom: 0;
      padding-bottom: 15px;
      min-height: 45px;
    }
    &__description {
      margin: 0 25px 15px;
      @include text-format(500, 16px, 90%);
      &-auth {
        margin: 0 25px 15px;
        @include text-format(500, 16px, 90%);
      }
    }
  }
}
