@import '../../../../styles/sсss/mixins';
@import '../../../../styles/sсss/variables';

.progress {
  display: flex;
  align-items: center;
  margin: 80px auto 15px;
  padding: 0;
  list-style-type: none;
  height: 33px;
  &__button-dots {
    border: .8px solid #000;
    color: #000;
    border-radius: 3px;
    background-color: #fff;
    @include size (30px, 30px);
    @include text-format (500, 20px, 0.66);
    padding: 0;
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
      transform: scale(1.1);
    }
  }
}