@import "../../../../../styles/sсss/mixins";
@import "../../../../../styles/sсss/variables";

.game {
    @include flex-column;
    justify-content: flex-start;
    gap: 3vh;
    box-sizing: border-box;
    padding: 40px 20px 14px;
    height: 100vh;
    width: 100vw;
    font-size: 16px;
    align-items: center;

    background-image:
    url('../../images/mascot.gif'),
    url('../../images/decor-top-left.svg'),
    url('../../images/decor-top-right.svg'),
    url('../../images/decor-bottom-left.svg'),
    url('../../images/decor-bottom-right.svg'),
    url('../../images/path.svg'),
    url('../../images/main-background1.svg');

    background-position:
        0 90%,
        -20% -112%,
        115% -88%,
        -12% 145%,
        113% 105%,
        bottom,
        center;

    background-size: 22%, 34%, 33%, 30%, 29%, 100%, cover;    
    background-repeat: no-repeat;
    overflow: hidden;

    &__content {
        @include flex-center-column;
        justify-content: space-around;
        color: #fff;
        flex-grow: 1;
    }

    &__title {
        text-align: center;
        margin: 17px 0 27px 0;
        padding: 0 10px;
        @include text-format(500, 24px, normal);
    }
}

.mascot {
    position: absolute;
    top: 50%;
    left: 50%;
}

.wordpath {
    position: relative;
    overflow: hidden;
    @include size(100vw, 130px); //100% ?    
}

.word {
    white-space: nowrap;
    animation-name: moveRight;
    animation-fill-mode: forwards;
    max-width: 100%;
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    @include text-format(500, 3.3em, 1);
    letter-spacing: 4px;
    
    &::after {
        content: "";
        background-image: url('../../images/rocket-wpath.svg');
        @include backgroundImage;
        @include size(260px, 130px);
    }
}

.word-wrapper {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include size(100vw, 130px);
    overflow: hidden;
    max-width: 100vw;
}

@keyframes moveRight {
    0% {
        transform: translateX(-100%);
    }
    50% {
        left: 50vw;
        transform: translateX(-50%);
    }
    100% {
        transform: translateX(100vw);
    }
}

.button {
    padding: 10px 20px;
    @include text-format(500, 1.4em, 1em);
    color: #274870;
    border-radius: 5px;
    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease;
    }
}

@media screen and (max-width: 640px) {
    .word {
        @include text-format(500, 2em, 1);
        letter-spacing: 2px;
        
        &::after {
            @include size(130px, 65px);
        }
    }
}

@media screen and (max-width: 480px) {
    .word {
        @include text-format(500, 1.4em, 1);
        letter-spacing: 1px;
        
        &::after {
            @include size(100px, 50px);
        }
    }
}

@supports (-webkit-touch-callout: none) {
    .game {
      height: -webkit-fill-available;
    }
  }