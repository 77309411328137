@import '../../../../styles/sсss/mixins';
@import '../../../../styles/sсss/variables';

.welcome-tests-page {
  width: 100vw;
  min-height: 100vh;
  @include flex-column;

  &__content {
    position: relative;
    @include flex-center;
    flex-direction: column;
    flex-grow: 1;
    min-height: 100%;
    background-image: url('../../../../assets/images/background-1440px.svg');
    background-size: auto;
    padding-bottom: 50px;

    &-title {
      margin: 100px auto 0;
    }

    &-description {
      margin: 20px auto 52px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .welcome-tests-page {
    &__content {
      background-image: url('../../../../assets/images/background-1024px.svg');
    }
  }
}

@media screen and (max-width: 768px) {
  .welcome-tests-page {
    &__content {
      background-image: url('../../../../assets/images/background-768px.svg');
    }
  }
}

@media screen and (max-width: 320px) {
  .welcome-tests-page {
    &__content {
      background-image: url('../../../../assets/images/background-320px.svg');
    }
  }
}
