@import '../../../styles/sсss/mixins';
@import '../../../styles/sсss/variables';

.insert-input {
  &__help-text {
    @include text-format(500, 18px, 86.7%);
    color: #706E6E;
    margin: 0 0 16px 0;
  }
  &__bottom-content {
    position: absolute;
    bottom: 15px;
  }
  &__input {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border: 1.5px solid $grey;
    border-radius: 5px;
    @include size (230px, 36px);
    padding: 0 11px;
    outline: none;
    @include text-format (400, 21px, 13.2px);
    &::placeholder {
      color: $grey;
      @include text-format (500, 21px, 13.2px);
    }
  }
  &__results-area {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  &__results-wrong {
    min-width: 193px;
    min-height: 36px;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    border: 1.5px solid #D47659;
    border-radius: 5px;
  }
  &__results-correct {
    min-width: 193px;
    min-height: 36px;
    height: fit-content;
    justify-content: space-between;
    display: flex;
    border: 1.5px solid #9EB81D;
    border-radius: 5px;
  }
  &__results-title-wrong {
    @include text-format (500, 21px, 0.62);
    width: 100px;
    color: #C31B1B;
    margin: 10px 10px 12px 11px;

  }
  &__results-title-correct {
    width: 120px;
    @include text-format (500, 21px, 0.62);
    color: #9EB81D;
    margin: 10px 10px 12px 11px;
  }
  &__results-answer {
    @include text-format (500, 21px, 0.62);
    margin: 10px 10px 12px 0;
  }
}

@media screen and (max-width: 1000px) {
  .insert-input {
    &__input {
      @include text-format (400, 20px, 13.2px);
      &::placeholder {
        color: $grey;
        @include text-format (500, 20px, 13.2px);
      }
    }
    &__results-title-wrong {
      @include text-format (500, 20px, 0.62);
    }
    &__results-title-correct {
      @include text-format (500, 20px, 0.62);
    }
    &__results-answer {
      @include text-format (500, 20px, 0.62);
    }
  }
}

@media screen and (max-width: 700px) {
  .insert-input {
    &__bottom-content {
      position: static;
    }
    &__input {
      width: 100%;
    }
    &__help-text {
      @include text-format(500, 15px, 86.7%);
    }
  }
}

@media screen and (max-width: 480px) {
  .insert-input {
    &__input {
      @include text-format (400, 18px, 13.2px);
      &::placeholder {
        color: $grey;
        @include text-format (500, 18px, 13.2px);
      }
    }
    &__results {
      &-title-wrong {
        margin: 10px 0 12px 11px;
        width: fit-content;
        @include text-format (500, 18px, 0.62);
      }
      &-title-correct {
        margin: 10px 0 12px 11px;
        width: fit-content;
        @include text-format (500, 18px, 0.62);
      }
      &-answer {
        @include text-format(500, 18px, 0.62);
      }
    }
  }
}