@import "../../../../../styles/sсss/mixins";
@import "../../../../../styles/sсss/variables";

.second-game {
  overflow: scroll;
  box-sizing: border-box;
  padding: 36px 20px 14px;
  min-height: 100vh;
  max-height: 100vh;
  width: 100vw;
  background-image:
          url('../../images/fish-group.svg'),
          url('../../images/seaweed.svg'),
          url('../../images/seaweed-2.svg'),
          url('../../images/seaweed-3.svg'),
          url('../../images/line-1.svg'),
          url('../../images/line-2.svg'),
          url('../../images/line-3.svg'),
          url('../../images/line-4.svg'),
          url('../../images/main-background.svg');
  background-position:
          left 2% top 11%,
          left -5% top -8%,
          right 0 top 19%,
          right 35% bottom -5%,
          left 0 top 10%,
          left 0 bottom 0,
          right 0 bottom 0,
          right 0 top 0,
          center;
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
  background-size: 3%, 11%, 7%, 5%, 15%, 7%, 18%, 6%, cover;
  @include flex-space-between-column;
  align-items: center;
  &__content {
    @include flex-center-column;
    width: 1200px;
  }

  &__title {
    flex-grow: 3;
    text-align: center;
    @include reset;
    margin: 20px 0 7px 0;
    @include text-format(500, 24px, normal);
    color: #21353e;
  }
}

.words-container {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  padding: 24px 0;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.08);
  @include flex-center;
  flex-wrap: wrap;
  gap: 6px;
  margin-bottom: 50px;
  &::before {
    z-index: 110;
    content: "";
    position: absolute;
    width: 150px;
    height: 180px;
    background-image: url('../../images/panda.gif');
    background-size: cover;
    background-repeat: no-repeat;
    left: -150px;
    bottom: -25px;
  }
}

.answers-container {
  box-sizing: border-box;
  position: relative;
  @include flex-center;
  flex-wrap: wrap;
  width: 100%;
  gap: 24px 6px;
  margin-bottom: 20px;
  &::before {
    content: "";
    position: absolute;
    width: 100px;
    height: 120px;
    background-image: url('../../images/seaweed-5.svg');
    background-size: cover;
    background-repeat: no-repeat;
    left: -50px;
    bottom: 20px;
  }
  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100px;
    height: 120px;
    background-image: url('../../images/seaweed-6.svg');
    background-size: cover;
    background-repeat: no-repeat;
    right: -50px;
    top: -50px;
  }
}

.buttons-area {
  display: flex;
  gap: 3%;
}

.answers-button {
  cursor: pointer;
  position: relative;
  margin: 0 auto;
  @include size(209px, 46px);
  border-radius: 5px;
  background-color: #fff;
  outline: none;
  transition: transform 0.3s ease;
  @include text-format(500, 24px, normal);
  color: #416371;
  border: 1px solid #5D95AC;
  &:hover {
    transform: scale(1.05);
  }
}

@media screen and (max-width: 1399px) {
  .second-game {
    padding: 27px 20px 14px;
    background-position:
            left 2% top 14%,
            left -5% top -9%,
            right 0 top 22%,
            right 41% bottom -5%,
            left -1% top 15%,
            left 0 bottom 0,
            right 0 bottom 0,
            right 0 top 0,
            center;
    background-size: 3%, 11%, 7%, 5%, 11%, 7%, 18%, 6%, cover;
    &__title {
      margin: 0px 0 30px 0;
    }
    &__content {
      width: 1116px;
    }
  }

  .words-container {
    &::before {
      width: 140px;
      height: 150px;
      left: -50px;
      bottom: -15px;
    }
  }

  .answers-container {
    gap: 18px 6px;
    &::before {
      width: 80px;
      height: 100px;
      left: 10px;
      bottom: 10px;
    }
    &::after {
      width: 80px;
      height: 100px;
      right: 0px;
    }
  }
}

@media screen and (max-width: 1150px) {
  .second-game {
    &__content {
      width: 100%;
    }
  }
  .words-container {
    padding: 24px 65px;
  }

  .answers-container {
    padding: 0 65px;
  }
}

@media screen and (max-width: 1129px) {
  .second-game {
    padding: 21px 20px 14px;
    background-image:
            url('../../images/fish-group.svg'),
            url('../../images/seaweed.svg'),
            url('../../images/seaweed-2.svg'),
            url('../../images/seaweed-3.svg'),
            url('../../images/line-1.svg'),
            url('../../images/line-2.svg'),
            url('../../images/line-3.svg'),
            url('../../images/line-4.svg'),
            url('../../images/main-bg-second-768.svg');
    background-position:
            left 15% top 7%,
            left -3% top -1%,
            right 0 top 26%,
            right 32% bottom -1%,
            left 0 top 40%,
            left 0 bottom 0,
            right -10% bottom 0,
            right 0 top 0,
            center;
    background-size: 3%, 11%, 10%, 5%, 20%, 15%, 24%, 12%, cover;
    &__title {
      margin: 12px 0 20px 0;
    }
    &__content {
      width: 590px;
    }
  }

  .words-container {
    margin-bottom: 10px;
    padding: 14px 0;
    gap: 6px 48px;
    &::before {
      width: 130px;
      height: 140px;
      left: -80px;
      bottom: 90px;
    }
  }

  .answers-container {
    padding: 0;
    margin-bottom: 10px;
    gap: 0 48px;
    &::before {
      left: -10px;
    }
    &::after {
      width: 80px;
      height: 100px;
      right: -20px;
      top: 30px;
    }
  }
}

@media screen and (max-width: 1029px) {
  .words-container {
    &::before {
      bottom: 290px;
    }
  }
}

@media screen and (max-width: 768px) {
  .correct-answers-container {
    width: 60%;
  }
}

@media screen and (max-width: 767px) {
  .second-game {
    padding: 14px 14px 3px;
    background-position:
            left 15% top 7%,
            left -3% top -1%,
            right 0 top 26%,
            right 32% bottom -1%,
            left 0 top 40%,
            left 0 bottom 0,
            right -10% bottom 0,
            right 0 top 0,
            center;
    background-size: 3%, 11%, 10%, 5%, 20%, 15%, 24%, 12%, cover;
    &__title {
      @include text-format(500, 18px, normal);
      flex-grow: 1;
    }
    &__content {
      width: 100%;
      flex-grow: 2;
    }
  }

  .words-container {
    margin-bottom: 25px;
    gap: 5px 17px;
    &::before {
      width: 90px;
      height: 100px;
      left: -10px;
      bottom: 210px;
    }
  }

  .answers-container {
    overflow: hidden;
    gap: 0 17px;
    &::after {
      right: -20px;
      top: 20px;
    }
  }

  .answers-button {
    @include size(188px, 43px);
    @include text-format(450, 20px, normal);
  }
}

@media screen and (max-width: 450px) {
  .second-game {
    overflow: hidden;
    overflow-y: scroll;
    padding: 14px;
  }

  .words-container {
    margin-bottom: 3px;
    &::before {
      left: -25px;
      bottom: 210px;
    }
  }

  .correct-answers-container {
    width: 80%;
  }
}

@media screen and (max-width: 380px) {
  .second-game {
    padding: 14px 14px 3px;
    &__title {
      @include text-format(500, 16px, normal);
      margin: 0 0 10px 0;
    }
  }

  .words-container {
    margin-bottom: 3px;
    &::before {
      width: 60px;
      height: 70px;
      bottom: 180px;
    }
  }

  .answers-container {
    margin-bottom: 5px;
    &::before {
      left: -10px;
      bottom: 55px;
      @include size(30px, 40px);
    }
    &::after {
      @include size(40px, 60px);
      right: -20px;
      top: 10px;
    }
  }

  .answers-button {
    @include size(108px, 24px);
    @include text-format(450, 12px, normal);
  }
}