@import '../../../../styles/sсss/mixins';
@import '../../../../styles/sсss/variables';

.carousel {
  position: relative;
  height: fit-content;
  display: flex;
  align-items: center;
  &__container {
    background-color: #fff;
    border: 2px solid #9eb81d;
    border-radius: 5px;
    box-sizing: border-box;
    margin: 0 auto;
    min-height: 340px;
    padding: 45px 26px 15px 38px;
    position: relative;
    width: 680px;
  }
  &__buttons-area {
    &_visible-1400px {
      display: none;
    }
  }
  &__button {
    @include size (63px, 63px);
    border: none;
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff;
    border-radius: 5px;
    &_forward {
      background-image: url('../../images/results-arrow-forward.svg');
      margin-left: 55px;
      &:hover {
        background-image: url('../../images/result-arrow-forward-hovered.svg');
        background-color: $greenText;
      }
    }
    &_back {
      background-image: url('../../images/results-arrow-back.svg');
      margin-right: 55px;
      &:hover {
        background-image: url('../../images/result-arrow-back-hovered.svg');
        background-color: $greenText;
      }
    }
  }
}

.hidden-button {
  visibility: hidden;
}

.carousel::after {
  content: "";
  position: absolute;
  top: -45px;
  right: 80px;
  background-image: url('../../../../assets/images/books.svg');
  @include backgroundImage;
  @include size (123px, 46px);
}

.question-number {
  margin: 0 0 30px 0px;
  text-align: center;
  @include text-format (500, 24px, auto);
}

@media screen and (max-width: 1400px) {
  .carousel {
    flex-direction: column;
    &::after {
      right: 0;
      top: 10px;
    }
    &__button {
      margin: 0;
      @include size (53px, 53px);
      &_hidden-1440px {
        display: none;
      }
    }
    &__buttons-area {
      &_visible-1400px {
        display: flex;
        justify-content: space-between;
        gap: 20px;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .carousel {
    max-width: 85%;
  }
}

@media screen and (max-width: 700px) {
  .carousel {
    &::after {
      display: none;
    }
  }
}

@media screen and (max-width: 480px) {
  .carousel {
    max-width: 95%;
  }

  .question-number {
    margin: 0 0 20px 0px;
    @include text-format (500, 20px, auto);
  }
}
