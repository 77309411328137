$md: 1024px;
$sm: 768px;
$xs: 320px;

$greenText: #9eb81d;
$grey: rgba(217, 217, 217, 1);
$yellow: #ffee5c;
$greenBackground: #d4e779;
$blueBackground: #98D1E3;
$whiteBackground: #ffffff;

$borderColor: #ef7c3b;
$errorColor: #c31b1b;

$fourth-game-primary: #ff9b64;
$fourth-game-disabled: #ff9b6466;
$fourth-game-text-primary: #551f00;
$fourth-game-text-secondary: #000000;
$fourth-game-correct: #C3D56F;
$fourth-game-incorrect: #ffcdb1;
