@import '../../../styles/sсss/mixins';
@import '../../../styles/sсss/variables';

.account-courses {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  &_popup-opened {
    height: 100vh;
    overflow: hidden;
  }
  &__content {
    position: relative;
    flex: 1 1 auto;
    @include flex-center;
    flex-direction: column;
    background-image: url('../../../assets/images/account-background-courses.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: -60px;
  }
  &__container {
    position: relative;
    margin: 263px auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 1134px;
    gap: 66px;
    &::before {
      content: "";
      position: absolute;
      top: -230px;
      left: 0;
      background-image: url('../images/account-panda-list.svg');
      @include backgroundImage;
      @include size (443px, 261px);
    }
  }
}

@media screen and (max-width: 1200px) {
  .account-courses {
    &__container {
      grid-template-columns: 100%;
      gap: 40px;
      width: 80%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .account-courses {
    &__content {
      background-image: url('../../../assets/images/background-1024px.svg');
    }
  }
}

@media screen and (max-width: 768px) {
  .account-courses {
    &__content {
      background-image: url('../../../assets/images/background-768px.svg');
    }
  }
}

@media screen and (max-width: 320px) {
  .account-courses {
    &__content {
      background-image: url('../../../assets/images/background-320px.svg');
    }
  }
}
