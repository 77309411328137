@import '../../../../styles/sсss/mixins';
@import '../../../../styles/sсss/variables';

.result-task__button {
  box-sizing: border-box;
  border: 1px solid #000;
  color: #000;
  border-radius: 3.3px;
  background-color: #fff;
  @include size (33px, 33px);
  @include text-format (500, 22px, 14.67px);
  padding: 0;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  &_odd-correct {
   background-image: url('../../images/result-task-btn-back-odd.svg');
  }
  &_even-correct {
    background-image: url('../../images/result-task-btn-back-even.svg');
  }
  &_odd-wrong {
    background-image: url('../../images/results-task-btn-odd-wrong.svg');
  }
  &_even-wrong {
    background-image: url('../../images/results-task-btn-even-wrong.svg');
  }
  &_odd-almost {
    background-image: url('../../../../assets/images/progress-btn-1.svg');
  }
  &_even-almost {
    background-image: url('../../../../assets/images/progress-btn-2.svg');
  }
}

@media screen and (max-width: 480px) {
  .result-task__button {
    @include size (30px, 30px);
    @include text-format (500, 18px, 14.67px);
  }
}

@media screen and (max-width: 380px) {
  .result-task__button {
    @include size (25px, 25px);
    @include text-format (500, 16px, 14.67px);
  }
}

