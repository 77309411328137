@font-face {
    font-family: 'Futura PT';
    font-weight: 400;
    src:
            url(FuturaPT-Light.woff2) format('woff2'),
            url(FuturaPT-Light.woff) format('woff');
}

@font-face {
    font-family: 'Futura PT';
    font-weight: 450;
    src:
            url(FuturaPT-Medium.woff2) format('woff2'),
            url(FuturaPT-Medium.woff) format('woff');
}

@font-face {
    font-family: 'Futura PT';
    font-weight: 500;
    src:
            url(FuturaPT-Book.woff2) format('woff2'),
            url(FuturaPT-Book.woff) format('woff');
}

@font-face {
    font-family: 'Futura PT';
    font-weight: 600;
    src:
            url(FuturaPT-Bold.woff) format('woff');
}