@import '../../../../../../../styles/sсss/mixins';
@import '../../../../../../../styles/sсss/variables';

.game-progress-bar {
  &__steps-wrapper {
    @include flex-center;
    height: 100%;
    flex: 1;
    box-sizing: border-box;

    @media screen and (max-width: 380px) {
      margin: 0 5px;
    }
  }

  &__steps {
    @include reset;
    height: 100%;
    position: relative;
    list-style: none;
    @include flex-space-between;
    margin: 0 24px;
    flex: 0 1 768px;
  }

  &__progress {
    @include size(76px, 53px);
    position: absolute;
    bottom: 0;
  }
}

@media screen and (max-width: 1149px) {
  .game-progress-bar {
    &__progress {
      @include size(56px, 43px);
      background-size: 100%;
      background-repeat: no-repeat;
      left: -20%;
    }
  }
}

@media screen and (max-width: 767px) {
  .game-progress-bar {
    &__progress {
      @include size(46px, 33px);
    }
  }
}