@import '../../../../styles/sсss/mixins';
@import '../../../../styles/sсss/variables';

.start-test {
  margin-top: 100px;
  position: relative;
  @include size(300px, 150px);
  @include backgroundImage;
  background-image: url(../../images/green-background-start-btn.png);

  &__button {
    position: absolute;
    top: -5px;
    left: 7px;
    color: #000;
    @include size(100%, 100%);
    @include text-format(500, 40px, 66.7%);
    border: 1.5px solid #000;
    border-radius: 15px;
    box-shadow: 0px 4px 4px 0px rgba(158, 184, 29, 0.74);
    @include backgroundImage;
    background-image: url(../../images/arrow-start-btn.svg);
    background-position-x: 50%;
    background-position-y: 75%;
    background-size: auto;
    background-color: transparent;
    cursor: pointer;
    &__link {
      @include linkStyle;
    }
  }
}

@media screen and (max-width: 800px) {
  .start-test {
    @include size(250px, 110px);
    background-size: 250px, 110px;
    border-radius: 15px;
    &__button {
      @include text-format(500, 35px, 66.7%);
      background-size: 60%;
    }
    &__link {
      @include linkStyle;
    }
  }
}

@media screen and (max-width: 400px) {
  .start-test {
    margin-top: 50px;
    @include size(200px, 90px);
    background-size: 200px, 90px;
    &__button {
      @include text-format(500, 30px, 66.7%);
      background-size: 60%;
    }
  }
}
