@import '../../../../styles/sсss/mixins';
@import '../../../../styles/sсss/variables';

.teachers {
  position: relative;
  padding: 50px 0;
  background-image: url('../../../../assets/images/background-1440px.svg'), url('../../images/teachers-lamps.svg');
  background-size: 100%, 20%;
  background-repeat: repeat-y, no-repeat;
  background-position: center, top left;
  &__title {
    @include text-format (500, 55px, 68.7%);
    color: $greenText;
    text-align: center;
    margin:  0;
  }
  &__content {
    margin-top: 35px;
    @include flex-center-column;
  }
  &__panda-img {
    background-color: #fff;
    @include size (600px, 541px);
    border-radius: 50% 50% 0 0;
    background-image: url('../../images/teachers-panda.svg');
    @include backgroundImage;
    background-position: center;
  }
}

@media screen and (max-width: 1024px) {
  .teachers {
    padding: 65px 0 50px;
    background-image: url('../../../../assets/images/background-1024px.svg'), url('../../images/teachers-lamps.svg');
    background-size: contain, 23%;
    background-position: center top, top left;
    &__panda-img {
      @include size (38%, 401px);
    }
  }
}

@media screen and (max-width: 800px) {
  .teachers {
    overflow: hidden;
    padding: 55px 0 50px;
    background-image: url('../../../../assets/images/background-768px.svg'), url('../../images/teachers-lamps.svg');
    background-position: center top, top left;
    &__panda-img {
      @include size (calc(100% - 350px), 350px);
    }
  }
}

@media screen and (max-width: 650px) {
  .teachers__panda-img {
    @include size (350px, 350px);
  }
}

@media screen and (max-width: 500px) {
  .teachers {
    padding: 30px 0 50px;
    background-image: none;
    &__title {
      @include text-format(500, 24px, 64.4%);
    }
    &__panda-img {
      @include size(250px, 250px);
      background-size: contain;
    }
  }
}
