@import '../../../styles/sсss/mixins';
@import '../../../styles/sсss/variables';

.error-popup {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.42);
  backdrop-filter: blur(7px);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: none;
  transition: visibility 0.1s, opacity 0.1s linear;
  &_opened {
    display: flex;
  }
  &__text {
    font-weight: bold;
    font-size: 24px;
    line-height: 29.05px;
    text-align: center;
    margin: 15px 30px;
  }
  &__container {
    @include size(820px, 600px);
    @include flex-center-column;
    justify-content: center;
    background-color: #ffffff;
    border: 1px solid $borderColor;
    border-radius: 18px;
    position: relative;
  }
  &__error-container {
    @include reset;
    @include flex-center-column;
    justify-content: center;
    box-sizing: border-box;
    border: 2px solid $greenText;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    position: relative;
    max-width: 600px;
    &::after {
      @include psevdoElement;
      @include size(30px, 60px);
      background-image: url('../images/Error-container.svg');
      @include backgroundImage;
      background-size: cover;
      background-position: -355px -17.5px;
      position: absolute;
      bottom: -29px;
      right: -8px;
    }
  }
  &__lamps {
    @include reset;
    @include size(115px, 115px);
    background-image: url('../images/Error-lamps.svg');
    @include backgroundImage;
    background-size: cover;
    position: absolute;
    top: -96px;
    left: -82px;
  }
  &__panda {
    @include reset;
    @include size(112px, 142px);
    background-image: url('../images/Error-panda.svg');
    @include backgroundImage;
    background-size: cover;
    position: absolute;
    bottom: -142px;
    right: -115px;
  }
}

@media screen and (max-width: 920px) {
  .error-popup {
    &__container {
      max-width: 90%;
    }
  }
}

@media screen and (max-width: 700px) {
  .error-popup {
    &__lamps {
      top: -146px;
      left: -82px;
    }
  }
}

@media screen and (max-width: 600px) {
  .error-popup {
    &__lamps {
      left: -20%;
    }
    &__panda {
      bottom: -172px;
      right: -25%;
    }
  }
}

@media screen and (max-width: 500px) {
  .error-popup {
    &__text {
      font-size: 20px;
      line-height: 1.2;
    }
    &__error-container {
      max-width: 70%;
    }
  }
}

@media screen and (max-width: 400px) {
  .error-popup {
    &__lamps {
      left: -10%;
    }
    &__panda {
      right: -15%;
    }
    &__text {
      font-size: 18px;
      line-height: 1.15;
    }
    &__error-container {
      max-width: 80%;
    }
  }
}
