@import '../../../../styles/sсss/mixins';
@import '../../../../styles/sсss/variables';

@keyframes bounce {
  0% {
    transform: translateY(0) scale(1);
  }
  50% {
    transform: translateY(20%) scale(1.1);;
  }
  100% {
    transform: translateY(0) scale(1);
  }
}

.test-results {
  &__content {
    position: relative;
    padding-top: 80px;
    padding-bottom: 70px;
    width: 100%;
    height: fit-content;
    @include flex-center-column;
  }
  &__score {
    text-align: center;
    margin: 35px auto;
    @include text-format(500, 32px, 1.28);
  }
  &__details {
    @include flex-center-column;
    margin-bottom: 50px;
  }
  &__details-button {
    display: block;
    background-image: url('../../images/results-arrow.svg');
    background-color: #fff;
    @include backgroundImage;
    background-size: auto;
    background-position-y: 70%;
    @include size (15px, 53px);
    border: none;
    cursor: pointer;
    animation: 1s ease-in infinite bounce;
  }
  &__details-info {
    box-sizing: border-box;
    margin: -140px auto 0;
    padding-bottom: 7px;
    text-align: center;
    @include text-format(500, 24px, 65.2%);
    border-bottom: 2px solid #D4E779;
    width: 153px;
  }
}

@media screen and (max-width: 1200px) {
  .test-results {
    &__details-info {
      margin: -130px auto 0;
    }
  }
}

@media screen and (max-width: 800px) {
  .test-results {
    padding-top: 20px;
    &__details {
      &-info {
        margin: -150px auto 0;
        @include text-format(500, 20px, 65.2%);
        width: 125px;
      }
      &-button {
        @include size (13px, 38px);
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .test-results {
    &__details {
      width: 100%;
      &-info {
        margin: 30px auto 0;
        @include text-format(500, 20px, 65.2%);
        width: 125px;
      }
      &-button {
        @include size (13px, 38px);
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .test-results {
    &__details {
      &-info {
        @include text-format(500, 18px, 10px);
        width: 100px;
      }
      &-button {
        @include size (13px, 38px);
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .test-results {
    &__details {
      &-info {
        @include text-format(500, 16px, 10px);
      }
    }
  }
}
