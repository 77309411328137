@import '../../../../../styles/sсss/mixins';
@import '../../../../../styles/sсss/variables';

.first-game {
  position: relative;
  overflow: hidden;
  overflow-y: scroll;
  box-sizing: border-box;
  padding: 26px 20px 14px;
  min-height: 100vh;
  max-height: 100vh;
  width: 100vw;
  @include flex-space-between-column;
  align-items: center;
  background-image:
          url('../../images/butterflyNearLines2.svg'),
          url('../../images/butterflyNearLines1.svg'),
          url('../../images/lines1.svg'),
          url('../../images/bambook1.svg'),
          url('../../images/bambook2.svg'),
          url('../../images/bambook3.svg'),
          url('../../images/lines2.svg'),
          url('../../images/lines3.svg'),
          url('../../images/2nd_layer.svg'),
          url('../../images/bg.svg');
  background-position:
          top 15% left 9%,
          top 17% left 4%,
          bottom 0 left 0,
          bottom 0 left 7%,
          bottom 0 right 21%,
          bottom 0 right 0,
          top 0 right 0,
          top 0 left 0,
          center,
          center;
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
  background-size: 14px, 18px, 20%, 5%, 5%, 6%, 21%, 19%, cover, cover;
  &-container {
    flex-grow: 2;
    display: flex;
    flex-direction: column;
  }
  &-title {
    @include flex-center;
    flex-grow: 2;
    text-align: center;
    @include reset;
    margin: 20px 0;
    @include text-format(500, 24px, normal);
    color: #1A1F00;
  }
  &-content {
    flex-grow: 3;
    width: 1300px;
  }
  &__butterfly-near-bambook1 {
    position: absolute;
    bottom: 7%;
    right: 7%;
  }
  &__butterfly-near-bambook2 {
    position: absolute;
    bottom: 16%;
    right: 5%;
  }
  &__butterfly-near-bambook3 {
    position: absolute;
    bottom: 20%;
    right: 1%;
  }
}

.groups-area {
  flex-grow: 2;
  display: flex;
  gap: 30px;
  &-container {
    @include flex-center-column;
    width: 50%;
    height: fit-content;
    box-sizing: border-box;
    position: relative;
    box-shadow: 0px 4px 4px #9eb81d40;
    border-radius: 5px;
    background-color: white;
    border: 1px solid #D9D9D9;
    padding: 15px 23px 21px;
    &-title {
      @include text-format(500, 24px, normal);
      color: #1A1F00;
      z-index: 2;
      position: relative;
      margin: 0;
      @include flex-center;
      width: 100%;
      height: 100%;
      border: 1px solid #9EB81D;
      border-radius: 5px;
      box-shadow: 1px 5px 5px 0px rgba(158,184,29,0.26);
      &-box {
        width: 65%;
        height: 50px;
        margin-bottom: 24px;
        position: relative;
      }
      &-background {
        position: absolute;
        width: 104%;
        &-one {
          top: -5px;
          left: -10px;
        }
        &-two {
          top: 2px;
          left: -10px;
        }
      }
    }
    &-list {
      @include flex-center;
      width: 100%;
      flex-wrap: wrap;
      gap: 12px 6px;
    }
    &-one {
      position: relative;
      &::before {
        z-index: 110;
        content: "";
        position: absolute;
        width: 100px;
        height: 120px;
        background-image: url('../../images/panda.gif');
        background-size: cover;
        background-repeat: no-repeat;
        left: 20px;
        top: -30px;
      }
      &::after {
        z-index: 110;
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        background-image: url('../../images/butterflyNearPanda.svg');
        background-size: cover;
        background-repeat: no-repeat;
        left: 90px;
        top: -30px;
      }
    }
  }
}

.initial-words-container {
  background-color: white;
  border: 1px solid $greenText;
  border-radius: 5px;
  margin: 15px auto;
  box-sizing: border-box;
  width: 100%;
  padding: 24px 0;
  @include flex-center;
  &-list {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 12px 6px;
  }
}

@media screen and (max-width: 1399px) {
  .first-game {
    &-content {
      width: 1110px;
    }
  }

  .initial-words-container {
    padding: 24px 58px;
  }
}

@media screen and (max-width: 1130px) {
  .first-game {
    background-image:
            url('../../images/lines1.svg'),
            url('../../images/bambook3.svg'),
            url('../../images/lines2.svg'),
            url('../../images/lines3.svg'),
            url('../../images/2nd-layer-768.svg'),
            url('../../images/bg-768.svg');
    background-position:
            bottom 0 left 0,
            bottom 0 right 0,
            top 0 right 0,
            top 0 left 0,
            center,
            center;
    background-size: 35%, 8%, 38%, 28%, cover, cover;
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
    &-content {
      width: 591px;
    }
  }

  .initial-words-container {
    padding: 15px 17px;
    &-list {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 85px;
    }
  }

  .groups-area {
    justify-content: space-between;
    &-container {
      padding: 26px 17px;
      width: 273px;
      &-title {
        &-box {
          width: 100%;
          margin-bottom: 18px;
        }
        &-background {
          object-fit: cover;
          height: 120%;
        }
      }
      &-list {
        flex-direction: column;
        row-gap: 5px;
      }
      &-one {
        &::before {
          width: 80px;
          height: 100px;
          left: 0;
          top: -70px;
        }
        &::after {
          left: 60px;
          top: -70px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .initial-words-container {
    &-list {
      width: 100%;
    }
  }
}

@media screen and (max-width: 630px) {
  .first-game {
    padding: 14px 11px 10px;
    &-container {
      width: 100%;
    }
    &-content {
      width: 100%;
    }
  }

  .groups-area {
    width: 100%;
    justify-content: space-between;
    &-container {
      width: 100%;
      max-width: 273px;
    }
  }

  .initial-words-container {
    width: 100%;
  }
}

@media screen and (max-width: 574px) {
  .groups-area {
    justify-content: center;
    column-gap: 5px;
    &-container {
      justify-self: stretch;
    }
  }

  .initial-words-container {
    &-list {
      column-gap: 10px;
    }
  }
}

@media screen and (max-width: 450px) {
  .groups-area {
    &-container {
      &-one {
        &::before {
          width: 60px;
          height: 80px;
          left: 0;
          top: -52px;
        }
        &::after {
          width: 15px;
          height: 15px;
          left: 50px;
          top: -52px;
        }
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .first-game {
    &-title {
      margin: 8px;
      @include text-format(500, 16px, normal);
    }
    &__butterfly-near-bambook1 {
      width: 11px;
    }
    &__butterfly-near-bambook2 {
      width: 11px;
    }
    &__butterfly-near-bambook3 {
      width: 11px;
    }
  }

  .groups-area {
    &-container {
      padding: 15px;
      &-title {
        margin-bottom: 10px;
        @include text-format(500, 16px, normal);
        &-box {
          height: 30px;
        }
      }
      &-list {
        row-gap: 2px;
      }
      &-one {
        &::before {
          width: 40px;
          height: 60px;
          left: 0;
          top: -42px;
        }
        &::after {
          width: 10px;
          height: 10px;
          left: 40px;
          top: -42px;
        }
      }
    }
  }

  .initial-words-container {
    margin: 15px 0 0;
    &-list {
      gap: 2px 10px;
    }
  }
}