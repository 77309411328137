@import '../../../styles/sсss/mixins';
@import '../../../styles/sсss/variables';

.footer {
  @include flex-center-column;
  padding-bottom: 25px;
  border-top: 1px solid rgba(158, 184, 29, 0.48);
  &__icons {
    @include reset;
    display: flex;
    gap: 30px;
    margin: 30px auto;
  }
  &__icon {
    @include size(45px, 45px);
    @include backgroundImage;
    &:first-child {
      background-image: url('../images/VK_Logo.svg');
    }
    &:nth-child(2) {
      background-image: url('../images/Telegram_log.svg');
    }
    &:last-child {
      background-image: url('../images/whatsUp_logo.svg');
      background-size: contain;
    }
    &:hover {
      @include hoverScale;
    }
  }
  &__text {
    @include reset;
    @include text-format(400, 21px, 14px);
    text-decoration: none;
    color: inherit;
    &:first-of-type {
      margin-bottom: 14px;
    }
  }
}
@media screen and (max-width: 1024px) {
  .footer {
    padding-bottom: 15px;
    &__icons {
      gap: 20px;
      margin: 20px auto;
    }
    &__icon {
      @include size(35px, 35px);
    }
    &__text {
      @include text-format(400, 18px, 12px);
    }
  }
}
@media screen and (max-width: 768px) {
  .footer {
    padding-bottom: 10px;
    &__icons {
      gap: 10px;
      margin: 10px auto;
    }
    &__icon {
      @include size(25px, 25px);
    }
    &__text {
      @include text-format(400, 14px, 8px);
      &:first-of-type {
        margin-bottom: 10px;
      }
    }
  }
}
