@import '../../../styles/sсss/mixins';
@import '../../../styles/sсss/variables';

.account-empty__banner {
  box-sizing: border-box;
  @include flex-center;
  padding: 0 84px;
  margin: 0;
  position: relative;
  background-color: #fff;
  @include size(715px, 184px);
  border: .5px solid #000;
  border-radius: 21px;
  text-align: center;
  @include text-format(400, 24px, 1.4);

  &::after {
    content: "";
    position: absolute;
    top: -126px;
    left: 27px;
    background-image: url('../../../pages/ProfileCourses/images/account-tests-panda.svg');
    @include backgroundImage;
    @include size(120px, 133px);
  }

  &::before {
    content: "";
    position: absolute;
    top: 6px;
    left: 3px;
    border: 1px solid $greenText;
    border-radius: 21px;
    @include size(704px, 175px);
  }
}

@media screen and (max-width: 900px) {
  .account-empty__banner {
    width: 80%;
    &::before {
      width: 98%;
    }
  }
}

@media screen and (max-width: 800px) {
  .account-empty__banner {
    height: 130px;
    &::before {
      height: 92%;
    }
    &::after {
      top: -100px;
      @include size(100px, 103px);
    }
  }
}

@media screen and (max-width: 550px) {
  .account-empty__banner {
    padding: 0 24px;
    @include text-format(400, 20px, 1.2);
    &::after {
      top: -82px;
      @include size(80px, 85px);
    }
  }
}
