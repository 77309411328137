@import '../../../../styles/sсss/mixins';
@import '../../../../styles/sсss/variables';

.ege {
  &__courses {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 35px;
    column-gap: 57px;
    margin: 0 41px 66px;
    max-width: 1060px;
  }
  &__svg {
    @include size(100%, 100%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  &__course {
    @include reset;
    min-width: 70px;
    max-height: 70px;
    @include text-format(400, 35px, 23px);
    border: 1px solid;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    box-shadow: 0px 2px 2px $greenText;
    position: relative;
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    &_big {
      min-width: 323px;
    }

    &-container {
      position: relative;
      background-color: white;
    }
    &-books {
      @include psevdoElement;
      background-image: url('../../../../assets/images/books.svg');
      @include backgroundImage;
      @include size(54px, 43px);
      position: absolute;
      top: -43px;
      left: 9px;
    }
    &-text {
      cursor: pointer;
    }
  }
}
@media screen and (max-width: 768px) {
  .ege {
    &_hiden {
      display: none;
    }
    &__courses {
      row-gap: 30px;
      column-gap: 30px;
      margin: 0 50px 193px;
    }
    &__course {
      background-size: 55px 55px;
      background-position: 0%;
      min-width: 55px;
      min-height: 55px;
      @include text-format(400, 16px, 77%);
      &-books {
        background-color: white;
        position: static;
      }
    }
  }
}
@media screen and (max-width: 645px) {
  .ege {
    &__courses {
      row-gap: 20px;
      column-gap: 20px;
      margin: 0 25px 193px;
    }
  }
}
