@import '../../../styles/sсss/mixins';
@import '../../../styles/sсss/variables';

.correspond {
  box-sizing: border-box;
  position: relative;
  background-color: #fff;
  max-width: 1128px;
  height: fit-content;
  border: 2px solid $greenText;
  border-radius: 5px;
  margin: 0 auto;
  padding: 30px 34px 17px;
  &__question-number {
    margin: 0 0 30px 0px;
    text-align: center;
    @include text-format (500, 24px, auto);
  }
  &__title {
    margin: 0 auto 24px;
    text-align: center;
    max-width: 80%;
    @include text-format (600, 24px, 1.28);
  }
  &__subtitle {
    margin: 0 auto 24px;
    text-align: justify;
    @include text-format (500, 24px, 1.28);
  }
  &__list {
    width: 100%;
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0 auto;
    &-item {
      width: 50%;
      border: 1px solid $greenText;
      border-radius: 5px;
      &:first-of-type {
        border-right: none;
      }
      &-inner {
        padding: 48px 0;
        display: flex;
        gap: 25px;
        width: 100%;
        flex-direction: column;
        align-items: center;
      }
      &-element {
        @include flex-space-between;
        width: calc(100% - 2*38px);
      }
      &-counter {
        display: flex;
        justify-content: center;
        align-items: center;
        @include size (27.38px, 25px);
        border: 1.5px solid $greenText;
        border-radius: 2px;
        @include text-format (500, 24px, 1.28);
        margin-top: 5px;
      }
      &-task {
        text-align: justify;
        width: calc(100% - (27.38px + 15.33px));
        @include text-format (500, 24px, 1.28);
        margin: 0;
      }
      &-text {
        margin: 0 auto;
        width: 90%;
        padding: 48px 0;
        @include text-format (500, 24px, 1.28);
        text-align: justify;
      }
    }
  }
}

.correspond-answers {
  width: fit-content;
  height: 90px;
  margin-top: 38px;
  &__buttons-area {
    display: flex;
    width: fit-content;
    justify-content: flex-end;
    align-items: center;
  }
  &__bottom-content {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  &__area {
    display: flex;
    justify-content: space-between;
    height: fit-content;
    &-item {
      margin-top: 12px;
    }
  }
  &__list {
    display: flex;
    padding: 0;
    margin: 0;
    list-style-type: none;
    height: 100%;
    border: .5px solid $grey;
    border-radius: 2px;
    &-wrong {
      border-color: #D47659;
      margin-right: 10px;
      height: 90px;
    }
    &-correct {
      border-color: #9EB81D;
      height: 90px;
    }
  }
  &__item {
    @include flex-column;
    @include size (107px, 100%);
    border: .5px solid $grey;
    &-wrong {
      border-color: #D47659;
      @include size (104px, 100%);
    }
    &-correct {
      border-color: #9EB81D;
      @include size (104px, 100%);
    }
    &-text {
      display: flex;
      justify-content: center;
      align-items: center;
      @include text-format (500, 24px, .55);
      margin: 0;
      width: 100%;
      height: 50%;
      border-bottom: 1px solid $grey;
      &-wrong {
        border-color: #D47659;
      }
      &-correct {
        border-color: #9EB81D;
      }
    }
    &-input {
      padding: 0;
      @include text-format (500, 24px, .55);
      text-align: center;
      width: 100%;
      height: 50%;
      outline: none;
      border: none;
      border-bottom: .5px solid $grey;
    }
  }
  &__title {
    margin: 0 0 12px 0;
    @include text-format (500, 21px, 0.62);
    &-wrong {
      color: #C31B1B;
    }
    &-correct {
      color: #9EB81D;
    }
  }
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: 600;
}

@media screen and (max-width: 1200px) {
  .correspond-answers {
    &__area {
      flex-direction: column;
      width: fit-content;
      &-item {
        width: fit-content;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .correspond {
    max-width: 100%;
    &-answers {
      align-self: flex-start;
      &__bottom-content {
        flex-direction: column;
        gap: 20px;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .correspond {
    &__list-item {
      &-task {
        @include text-format (500, 20px, 1.2);
      }
      &-counter {
        @include text-format (500, 20px, 1.28);
      }
      &-text {
        @include text-format (500, 20px, 1.28);
      }
    }
    &__title {
      @include text-format (600, 20px, 1.2);
    }
    &__subtitle {
      @include text-format (500, 20px, 1.2);
    }
    &-answers {
      &__item {
        &-text {
          @include text-format(500, 20px, 1.2);
        }
        &-input {
          @include text-format (500, 20px, .55);
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .correspond {
    padding: 20px 15px 17px;
    &__title {
      max-width: 90%;
    }
    &__subtitle {
      max-width: 90%;
    }
    &__list {
      &-item {
        &-inner {
          padding: 20px 0;
        }
        &-text {
          padding: 20px 0;
        }
        &-element {
          width: 90%;
        }
        &-counter {
          @include size (20px, 20px);
          @include text-format (500, 20px, 1.28);
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .correspond {
    border: none;
    &__title {
      text-align: justify;
    }
    &__list {
      flex-direction: column;
      width: 100%;
      gap: 20px;
      &-item {
        width: 100%;
        border: 1px solid $greenText;
        &:first-of-type {
          border: 1px solid $greenText;
        }
      }
    }
    &-answers {
      &__item {
        width: 60px;
      }
      &__title {
        @include text-format (500, 20px, 1.28);
      }
      &__buttons-area {
        align-self: flex-start;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .correspond {
    &__list-item {
      &-task {
        @include text-format (500, 18px, 1.2);
      }
      &-counter {
        @include text-format (500, 18px, 1.2);
      }
      &-text {
        @include text-format (500, 18px, 1.2);
      }
    }
    &__title {
      @include text-format (600, 18px, 1.2);
    }
    &__subtitle {
      @include text-format (500, 18px, 1.2);
    }
    &-answers {
      &__item {
        &-text {
          @include text-format (500, 18px, 1.2);
        }
        &-input {
          @include text-format (500, 18px, .55);
        }
      }
      &__title {
        @include text-format (500, 18px, 1.28);
      }
    }
    &__question-number {
      margin: 0 0 20px 0px;
      @include text-format (500, 20px, auto);
    }
  }
}

@media screen and (max-width: 350px) {
  .correspond {
    &-answers {
      &__item {
        width: 55px;
      }
    }
  }
}
