@import '../../../styles/sсss/mixins';
@import '../../../styles/sсss/variables';

.registration {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-image: url('../../../assets/images/Вход.svg');
  @include backgroundImage;
  background-size: 100%;
  background-position: middle center;
  min-height: 930px;

  &__container {
    width: 680px;
    height: auto;
    box-shadow: 11px 11px 14px rgba(97, 97, 97, 0.12);
    border: 2px solid $greenText;
    border-radius: 10px;
    background-color: white;
    @include flex-center-column;
    position: relative;
    box-sizing: border-box;
    margin-top: 65px;
  }
  &__input {
    @include reset;
    border: 2px solid $greenText;
    width: 99%;
    min-height: 59px;
    @include text-format(300, 24px, 18px);
    border-radius: 5px;
    text-align: center;
    box-sizing: border-box;
    &:focus {
      outline: 0;
      outline-offset: 0;
    }
    &-container {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      margin-top: 18px;
      &:first-of-type {
        margin-top: 0;
      }
    }
    &_error {
      border: 2px solid $errorColor;
    }
  }
  &__form {
    @include flex-column;
    @include reset;
    justify-content: center;
    align-items: center;
    margin-top: 34px;
    position: relative;
    width: 502px;
    &_errors {
      margin-top: 40px;
    }
  }
  &__save-button {
    @include reset;
    @include size(251px, 59px);
    @include text-format(300, 24px, 18px);
    box-shadow: 11px 11px 14px rgba(97, 97, 97, 0.12);
    border: 0.5px solid;
    border-radius: 15px;
    background-image: url('../../../assets/images/Login_button.svg');
    @include backgroundImage;
    background-size: 100%;
    background-color: inherit;

    &-container {
      position: absolute;
      bottom: -110px;
      background-color: inherit;
    }
    &-left-lamp {
      background-image: url('../../../assets/images/left_lamp.svg');
      @include backgroundImage;
      @include size(90px, 90px);
      position: absolute;
      top: -50px;
      left: -120px;
    }
    &-right-lamp {
      background-image: url('../../../assets/images/right_lamp.svg');
      @include backgroundImage;
      @include size(90px, 90px);
      position: absolute;
      top: -13px;
      right: -120px;
    }
    &:hover {
      @include hoverScale;
    }
  }
  &__checkbox {
    opacity: 0;
    @include size(1px, 1px);
    &:checked + .registration__checkbox-new {
      background-color: $greenBackground;
    }
    &-new {
      display: inline-block;
      @include reset;
      @include size(14px, 14px);
      border: 0.5px solid;
      margin-right: 10px;
      padding-right: 10px;
      box-sizing: border-box;
    }
    &-info {
      cursor: default;
      margin: 24px auto 34px;
      max-width: 279px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &_active {
      background-color: $greenBackground;
    }
  }
  &__text {
    @include reset;
    @include text-format(300, 24px, 18px);
    text-decoration: none;
    color: inherit;
    max-width: 242px;
  }
  &__eye {
    @include reset;
    @include size(48px, 19px);
    background-image: url('../../../assets/images/Glaz_otkrytiy.svg');
    @include backgroundImage;
    background-size: 100%;
    background-position: middle center;
    border: none;
    background-color: inherit;
    position: absolute;
    top: 20px;
    right: -68px;
    &_close {
      background-image: url('../../../assets/images/Glaz_zakritiy.svg');
      @include size(48px, 21px);
      top: 18px;
    }
  }
  &__button {
    @include reset;
    @include size(251px, 37px);
    @include text-format(300, 24px, 18px);
    border: 1px solid $borderColor;
    border-radius: 5px;
    background-color: inherit;
    margin-top: 18px;
    cursor: pointer;
  }
  &__error {
    @include reset;
    @include text-format(300, 18px, 12px);
    color: $errorColor;
    text-align: left;
    margin-top: 6px;
  }
}

@media screen and (max-width: 950px) {
  .registration {
    &__container {
      max-width: 72%;
    }
    &__form {
      width: 74%;
    }
    &__input {
      &-container {
        width: 100%;
      }
    }
    &__eye {
      right: 25px;
    }
  }
}

@media screen and (max-width: 645px) {
  .registration {
    &__input {
      border: 1px solid $greenText;
      min-height: 49px;
      @include text-format(300, 22px, 18px);
      &_error {
        border: 1px solid $errorColor;
      }
    }
    &__eye {
      top: 16px;
      @include size(40px, 16px);
    }
    &__error {
      @include text-format(300, 16px, 12px);
    }
    &__text {
      @include text-format(300, 22px, 18px);
    }
  }
}
@media screen and (max-width: 600px) {
  .registration {
    &__container {
      max-width: 90%;
    }
    &__button {
      @include size(230px, 45px);
      @include text-format(300, 20px, 18px);
    }
    &__text {
      @include text-format(300, 18px, 18px);
    }
    &__input {
      min-height: 45px;
      @include text-format(300, 18px, 18px);
    }
    &__eye {
      top: 15px;
    }
    &__error {
      @include text-format(300, 14px, 12px);
    }
    &__form {
      width: 89%;
    }
    &__save-button {
      &-left-lamp {
        display: none;
      }
      &-right-lamp {
        display: none;
      }
      @include size(231px, 49px);
      @include text-format(300, 20px, 18px);
    }
  }
}
