@import '../../../styles/sсss/mixins';
@import '../../../styles/sсss/variables';

.single-answer {
  &__container {
    display: block;
    width: fit-content;
    vertical-align: bottom;
    position: relative;
    margin: 0 0 15px 0;
    cursor: pointer;
    font-size: 13px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  &__mark {
    box-sizing: border-box;
    position: absolute;
    top: 5px;
    left: 0;
    @include size(25px, 25px);
    border-radius: 50%;
    background-color: transparent;
    border: 1.5px solid $grey;
    &:after {
      box-sizing: border-box;
      content: "";
      position: absolute;
      display: block;
      top: 2.5px;
      left: 2.5px;
      @include size(17px, 17px);
      border-radius: 50%;
      background-color: #fff;
      border: 1px solid $grey;
    }
  }
  &__result-circle {
    box-sizing: border-box;
    margin-top: 5px;
    margin-right: 13.5px;
    padding-right: 5px;
    border: 1.5px solid #D9D9D9;
    border-radius: 50%;
    @include size (26px, 26px);
    &-inner {
      position: relative;
      left: 2.5px;
      top: 2.5px;
      border: 1px solid #D9D9D9;
      border-radius: 50%;
      @include size (16px, 16px);
      &_wrong {
        border-color: #D47659;
        background-color: #D47659;
      }
      &_correct {
        border-color: #9EB81D;
        background-color: #9EB81D;
      }
    }
  }
  &__result-list {
    list-style-type: none;
    padding: 0;
    margin: 37px 0 0;
  }
  &__result-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    height: fit-content;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.single-answer__input:checked ~ .single-answer__mark:after {
  background-color: $yellow;
  border: $yellow;
  left: 2.5px;
}


