@import '../../../../styles/sсss/variables.scss';
@import '../../../../styles/sсss/mixins.scss';

.portal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  background: rgba($whiteBackground, 0.8);
  max-height: 100vh;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
}

.portal__wrapper {
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 49px 20px;
}

.portal__body {
  max-width: 100%;
  max-height: 100%;
}