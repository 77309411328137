@import '../../../styles/sсss/mixins';
@import '../../../styles/sсss/variables';

.in-develop-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  &__content {
    flex: 1 1 auto;
    @include flex-center;
  }
}
