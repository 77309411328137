@import '../../../styles/sсss/mixins';
@import '../../../styles/sсss/variables';

.account-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  &_popup-opened {
    height: 100vh;
    overflow: hidden;
  }
  &__content {
    position: relative;
    min-height: 627px;
    @include flex-center;
    flex-direction: column;
    flex: 1 1 auto;
    background-image: url('../images/panda-account-main.svg'), url('../../../assets/images/background-1440px.svg');
    background-repeat: no-repeat, repeat-y;
    background-size: 13%, cover;
    background-position: 95% 95%, top center;
  }
  &__buttons-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  &__link {
    text-decoration: none;
  }
  &__button {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding-left: 114px;
    margin: 0;
    position: relative;
    @include size (715px, 122px);
    background-color: #fff;
    border: .5px solid #000;
    border-radius: 100px;
    @include text-format(400, 24px, 67.7%);
    color: #000;
    background-image: url('../images/account-page-arrow.svg');
    background-repeat: no-repeat;
    background-position: 645px;
    cursor: pointer;
    transition: all 0.4s;
    &:hover {
      transform: scale(1.03);
    }
    &::before {
      content: "";
      position: absolute;
      top: 6px;
      left: 3px;
      border: 1px solid $greenText;
      border-radius: 100px;
      @include size (706px, 112px);
    }
    &-first {
      margin-bottom: 66px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .account-page {
    &__content {
      background-image: url('../images/panda-account-main.svg'), url('../../../assets/images/background-1024px.svg');
    }
  }
}

@media screen and (max-width: 900px) {
  .account-page {
    &__content {
      background-size: 22%, cover;
    }
    &__buttons-list {
      width: 70%;
    }
    &__button {
      width: 100%;
      background-position: top 50% right 50px;
      &::before {
        width: 99%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .account-page {
    &__content {
      background-image: url('../images/panda-account-main.svg'), url('../../../assets/images/background-768px.svg');
    }
  }
}

@media screen and (max-width: 650px) {
  .account-page {
    &__content {
      background-size: 25%, cover;
    }
    &__buttons-list {
      height: 90px;
    }
    &__button {
      height: 100%;
      &::before {
        height: 90%;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .account-page {
    &__content {
      background-size: 30%, cover;
    }
    &__buttons-list {
      height: 68px;
    }
    &__button {
      padding-left: 20px;
      @include text-format(400, 20px, 67.7%);
      background-position: top 50% right 20px;
    }
  }
}

@media screen and (max-width: 320px) {
  .account-page {
    &__content {
      background-image: url('../images/panda-account-main.svg'), url('../../../assets/images/background-320px.svg');
    }
  }
}
