@import '../../../../styles/sсss/mixins';
@import '../../../../styles/sсss/variables';

.main {
  @include flex-center-column;
  position: relative;
  min-height: 80vh;
  @include backgroundImage;
  background-image: url('../../images/Main-panda_books.svg'),
  url('../../images/Main-string_lamp.svg'), url('../../images/Main-lamps.svg'),
  url('../../images/Main-books1.svg'), url('../../images/Main-books2.svg'),
  url('../../images/Main-books3.svg'), url('../../images/Main-books4.svg'),
  url('../../images/Main-books5.svg'), url('../../images/Main-books6.svg'),
  url('../../images/Main-circles.svg'), url('../../images/Main-circles2.svg');
  background-position-x: 100%, 100%, 0, 56%, 100%, 57%, 5%, 73%, 25%, 100%, 0;
  background-position-y: 102%, 0, -0.5%, 33%, 38%, 103%, 85%, 9%, 4%, 80%, 103%;
  background-size: auto, 215px, auto, auto, auto, auto, auto, auto, auto, auto, 10%;

  &__title {
    margin: 40px auto 5px;
    white-space: nowrap;
  }

  &__subtitle {
    @include reset;
    @include text-format(400, 30px, 121.2%);
    margin: 0 auto 24px;
  }

  &__description {
    margin: 0 auto 46px;
    @include text-format(500, 21px, 108.7%);
    max-width: 800px;
    text-align: left;
    white-space: pre-wrap;
  }
}

@media screen and (max-width: 1024px) {
  .main {
    background-position-x: 100%, 100%, 0, 56%, 100%, 57%, 5%, 73%, 29%, 100%, 0;
    background-position-y: 102%, 0, -0.5%, 33%, 38%, 103%, 85%, 9%, 7%, 80%, 103%;
    background-size: 25%, 25%, 25%, auto, auto, 14%, 13%, 12%, 10%, auto, 10%;
  }
}
@media screen and (max-width: 768px) {
  .main {
    background-size: 35%, 0, 0, 14%, 14%, 14%, 13%, 12%, 10%, 10%, 10%;
    &__subtitle {
      margin: 0 50px 24px;
      text-align: center;
    }
    &__description {
      margin: 0 50px 25px;
      @include text-format(500, 18px, 88%);
      text-align: center;
    }
  }
}

@media screen and (max-width: 700px) {
  .main {
    background-position-x: 50%, 100%, 0, 56%, 100%, 57%, 5%, 73%, 25%, 100%, 0;
    background-position-y: 101%, 0, -0.5%, 33%, 38%, 103%, 85%, 9%, 4%, 80%, 103%;
    background-size: 45%, 0, 0, 14%, 14%, 14%, 13%, 12%, 10%, 10%, 10%;
  }
}