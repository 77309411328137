@import "../../../../../styles/sсss/mixins";

.answer-btn {
    display: flex;
    gap: 5vw;
    color: #21353e;

    &__variant {
        color: #274870;
        @include text-format(400, 2.8em, 1em);
        text-align: center;
        padding: 10px;
        background-color: #fff;
        border-radius: 50px;
        height: 80px;
        min-width: 80px;
        border: #719FD6 2px solid;
        cursor: pointer;
        transition: all 0.2s ease-out;
        &:disabled {
            color: grey;
            background-color: #b1b1b1;
            border-color: grey;
            scale: 0.8;
            transition: all 0.2s ease-out;
        }

        
    }
    &__variant.chosen {
        &:disabled {
            background-color: #fff;
            font-weight: 400;
            scale: 1.2;
            color: #9770B1;
            border-color: #6f458b;
            box-shadow: 0 0 10px #9168AD;
        }
    }
}

@media (max-width: 480px) {
    
    .answer {
            
        &__variant {
            font-size: 2em;
            padding: 6px;
            border-radius: 40px;
            height: 60px;
            min-width: 60px;
        }
    }
}