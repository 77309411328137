@import '../../../styles/sсss/mixins';
@import '../../../styles/sсss/variables';

.about {
  @include flex-center-column;
  position: relative;
  min-height: 100vh;
  background-image: url('../images/About.svg');
  background-repeat: no-repeat;
  background-size: 100vw;
  background-position: top center;
  &__video-button {
    @include size(485px, 485px);
    @include reset;
    border: none;
    background-color: white;
    background-image: url('../images/Play.svg');
    @include backgroundImage;
    margin-left: 10px;
  }
  &__content {
    display: flex;
    margin: 0 105px;
  }
  &__title {
    margin: 40px auto 0;
    background-image: none;
    min-height: 35px;
  }
  &__panda {
    @include size(22vw, 22vw);
    background-image: url('../images/About-panda.svg');
    @include backgroundImage;
    position: absolute;
    left: 215px;
    bottom: 0;
  }
  &__appeal {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 89px 0 0 0;
    width: 50%;
    &-title {
      @include reset;
      @include text-format(500, 32px, 108.7%);
      text-align: center;
    }
    &-text {
      @include reset;
      @include text-format(400, 24px, 127.2%);
      text-align: justify;
      margin-top: 50px;
    }
  }
  &__media {
    @include flex-column;
    align-items: center;
    width: 50%;
    min-height: 59em;
    position: relative;
    &-info {
      padding: 40px;
      border: 1px solid $greenText;
      border-radius: 0 10px 10px 10px;
      border-top: none;
      position: absolute;
      margin: 65px 0 30px 0;
      background-color: white;
      bottom: 0px;
      width: auto;
      &:before {
        content: ' ';
        @include size(202px, 32px);
        position: absolute;
        background-image: url('../images/Border-top.svg');
        right: -1.48px;
        top: -21px;
      }
      &:after {
        content: ' ';
        @include size(57px, 28px);
        position: absolute;
        background-image: url('../images/about-books.svg');
        @include backgroundImage;
        right: 11px;
        top: -27px;
      }
    }
    &-text {
      @include reset;
      max-width: 540px;
    }
    &-mask {
      @include reset;
      @include size(3px, 50px);
      background-color: white;
      position: absolute;
      top: 0;
      left: -2px;
    }
    &-bamboo {
      @include size(110px, 239px);
      position: absolute;
      background-image: url('../images/about-bamboo.svg');
      @include backgroundImage;
      right: -31px;
      bottom: -30px;
    }
    &-quote {
      display: block;
      position: absolute;
      @include size(40px, 58px);
      background-image: url('../images/quote.svg');
      @include backgroundImage;
      background-position: 0 0;
      background-color: inherit;
      bottom: -58px;
      left: -1px;
    }
    &-iframe {
      @include size(500px, 51%);
      margin-top: 30px;
      border-radius: 10px;
      margin-left: 30px;
    }
    &_hiden {
      display: none;
    }
  }
}
@media screen and (max-width: 1240px) {
  .about {
    &-text {
      margin-right: 15px;
    }
    &__video-button {
      @include size(100%, 485px);
      margin-left: 15px;
    }
    &__media-iframe {
      @include size(100%, 51%);
    }
  }
}
@media screen and (max-width: 1024px) {
  .about {
    background-image: url('../images/About-1024.svg');
    &__title {
      margin: 160px auto 0;
    }
    &__content {
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
      width: 100%;
    }
    &__appeal {
      width: auto;
      margin: 105px 190px 0;
    }
    &__video-button {
      @include size(485px, 485px);
      margin: 110px auto;
    }
    &__media {
      width: auto;
      margin: 0 190px 0;
      &-info {
        position: relative;
        margin-bottom: 450px;
        margin-top: 0;
        border-bottom-left-radius: 0;
      }
      &-bamboo {
        right: 0px;
        bottom: 0px;
        @include size(111px, 240px);
      }
      &-iframe {
        @include size(100%, 485px);
        margin: 110px auto;
      }
    }
    &__panda {
      @include size(310px, 310px);
      left: 5px;
    }
  }
}
@media screen and (max-width: 768px) {
  .about {
    background-image: url('../images/About-768.svg');
    &__title {
      margin: 105px auto 0;
    }
    &__appeal {
      margin: 105px 80px 0;
    }
    &__media {
      margin: 0 80px 0;
    }
  }
}
@media screen and (max-width: 645px) {
  .about {
    background-image: url('../images/About-768.svg');
    &__title {
      margin: 60px auto 0;
      padding: 10px 0 0;
      @include text-format(500, 24px, 67.7%);
      min-height: 21px;
    }
    &__appeal {
      margin: 40px 30px 0;
      &-title {
        @include text-format(500, 21px, 144%);
        margin-bottom: 40px;
        max-width: 170px;
      }
      &-text {
        margin-top: 0;
        @include text-format(400, 16px, 144%);
      }
    }
    &__video-button {
      @include size(485px, 485px);
      margin: 25px auto;
    }
    &__media {
      margin: 0 30px 0;
      min-height: 100%;
      &-info {
        padding: 10px;
        margin-bottom: 160px;
        border: 0.5px solid $greenText;
        border-top: none;
        &::before {
          height: 35px;
          width: 125px;
          position: absolute;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: 0px 10px;
          right: -1px;
          top: -22px;
        }
        &:after {
          @include size(29px, 14px);
          top: -14px;
        }
      }
      &-iframe {
        @include size(100%, 50vw);
        margin: 25px auto;
      }
      &-bamboo {
        @include size(23px, 50px);
        right: 0;
        bottom: 0;
      }
      &-mask {
        height: 30px;
      }
      &-quote {
        @include size(15px, 15px);
        background-image: url('../images/quote-small.svg');
        bottom: -15px;
        left: -1px;
      }
    }
    &__video-button {
      @include size(180px, 180px);
      margin: 25px auto;
    }

    &__panda {
      @include size(92px, 92px);
      left: 2px;
    }
  }
}
