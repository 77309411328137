@import '../../../../styles/sсss/mixins';
@import '../../../../styles/sсss/variables';

.multiple-answer {
  &__result-square {
    box-sizing: border-box;
    margin-top: 5px;
    padding-right: 5px;
    border: 3px solid #D9D9D9;
    border-radius: 5px;
    @include size (25px, 25px);
    &_wrong {
      border-color: #D47659;
      background-color: #D47659;
    }
    &_correct {
      border-color: $greenText;
      background-color: $greenText;
    }
    &_not-answered {
      border: 3px solid $greenText;
      background-color: #fff;
    }
    &-inner {
      box-sizing: border-box;
      position: relative;
      left: 1px;
      top: 1px;
      @include size (17px, 17px);
      &_wrong {
        background-color: #D47659;
        background-image: url('../../images/results-input-wrong.svg');
        background-repeat: no-repeat;
        background-position: center;
      }
      &_correct {
        background-color: $greenText;
        background-image: url('../../images/results-input-correct.svg');
        background-repeat: no-repeat;
        background-position: center;
      }
      &_not-answered {
        background-color: #fff;
        background-image: url('../../images/results-input-not-answered.svg');
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}