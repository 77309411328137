@import '../../../../styles/sсss/mixins';
@import '../../../../styles/sсss/variables';

.progress-button {
  border: .8px solid #000;
  color: #000;
  border-radius: 3px;
  background-color: #fff;
  @include size (30px, 30px);
  @include text-format (500, 20px, 0.66);
  padding: 0;
  &_solved-even {
    @include backgroundImage;
    background-image: url('../../../../assets/images/progress-btn-1.svg');
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
  }
  &_solved-odd {
    cursor: pointer;
    @include backgroundImage;
    background-image: url('../../../../assets/images/progress-btn-2.svg');
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
  }
}

#active {
  transform: scale(1.1);
}

#not-active {
  transform: scale(1);
}

@media screen and (max-width: 480px) {
  .progress-button {
    @include size (30px, 30px);
    @include text-format (500, 18px, 14.67px);
  }
}

@media screen and (max-width: 380px) {
  .progress-button {
    @include size (25px, 25px);
    @include text-format (500, 16px, 14.67px);
  }
}
