@import "../../../../styles/sсss/mixins";
@import "../../../../styles/sсss/variables";

.container {
  width: 187.47px;
}

.word {
  &-container {
    box-sizing: border-box;
    position: relative;
    border-radius: 5px;
    width: 100%;
    height: 41.01px;
    background-color: white;

    &::before {
      content: "";
      position: absolute;
      z-index: 30;
      @include size(25px, 25px);
      background-size: cover;
      background-repeat: no-repeat;
      right: -5px;
      bottom: 25px;
    }

    &-title {
      color: #21353E;
      margin: 0 0 12px 0;
      @include text-format(500, 22px, normal);
      text-align: center;
    }

    &-one {
      border: 2px solid #D4D7C7;
      &-filled {
        border: 1px solid #9EB81D;
      }
    }
    &-two {
      border: 1px solid #339E8B;
    }
  }

  &-card {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    background-color: white;
    @include text-format(500, 24px, normal);
    @include flex-center;
    &-one {
      color: #1A1F00;
    }
    &-two {
      color: #416371;
    }
  }
}

.correct {
  &::before {
    background-image: url('../../images/true.svg');
  }
}

.wrong {
  &::before {
    background-image: url('../../images/false.svg');
  }
}

.hovered {
  &-one {
    border: 1px solid #9EB81D;
  }
  &-two {
    border: 1px solid #339E8B;
  }
}

@media screen and (max-width: 1399px) {
  .container {
    width: 160px;
  }

  .word {
    &-container {
      height: 35px;
      &-title {
        @include text-format(500, 18px, normal);
      }
    }
    &-card {
      @include text-format(500, 22px, normal);
    }
  }
}

@media screen and (max-width: 1150px) {
  .container {
    width: 150px;
  }

  .word {
    &-container {
      height: 35px;
      &-title {
        @include text-format(500, 18px, normal);
      }
    }
    &-card {
      @include text-format(500, 22px, normal);
    }
  }
}

@media screen and (max-width: 1029px) {
  .container {
    width: 233px;
  }

  .word {
    &-container {
      height: 46px;
      &-title {
        @include text-format(500, 24px, 29px);
        margin: 0;
      }
      &-one {
        border: 1px solid #D4D7C7;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .container {
    width: 43%;
    &-one {
      width: 100%;
    }
  }
  .word {
    &-container {
      height: 30px;
      &::before {
        @include size(15px, 15px);
        bottom: 15px;
      }
      &-title {
        @include text-format(500, 18px, 14px);
        margin: 5px;
      }
    }
    &-card {
      @include text-format(500, 18px, normal);
    }
  }
}

@media screen and (max-width: 380px) {
  .word {
    &-container {
      height: 25px;
      &-title {
        @include text-format(500, 14px, 14px);
        margin: 0;
      }
    }
    &-card {
      @include text-format(500, 14px, normal);
    }
  }
}