@import "../../../../../styles/sсss/mixins";
@import "../../../../../styles/sсss/variables";

.correct-answers-block {
  @include flex-center-column;
}

.correct-answers-block__title-inner {
  flex-grow: 2;
  @include text-format(500, 28px, normal);
  margin: 7px auto 96px;
  @include size(423px, 50px);
  background-color: #fff;
  border-radius: 5px;
  @include flex-center;
  color: #1A1F00;
  position: relative;
  border: 1px solid #339E8B;
  box-shadow: 0px 4px 4px 0px rgba(100, 133, 248, 0.25);
  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: 10;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &::before {
    @include size(60px, 60px);
    background-image: url('../../../../../assets/images/left-img-two.svg');
    left: -30px;
    top: -25px;
  }

  &::after {
    @include size(60px, 60px);
    background-image: url('../../../../../assets/images/right-img-two.svg');
    right: -20px;
    bottom: -2px;
  }
}

.second-game-answers-list {
  padding: 0;
  max-width: 1200px;
  list-style-type: none;
  box-sizing: border-box;
  @include flex-center;
  flex-wrap: wrap;
  gap: 24px 6px;
  margin-bottom: 20px;
  &__item {
    &-title {
      color: #21353E;
      margin: 0 0 12px 0;
      @include text-format(500, 22px, normal);
      text-align: center;
    }
    &-box {
      position: relative;
      border: 1px solid #339E8B;
      border-radius: 5px;
      width: 187.47px;
      height: 41.01px;
      background-color: white;
      color: #416371;
      @include text-format(500, 24px, normal);
      @include flex-center;
    }
  }
}

@media screen and (max-width: 1399px) {
  .second-game-answers-list {
    &__item {
      &-title {
        @include text-format(500, 18px, normal);
      }

      &-box {
        @include size(160px, 35px);
        @include text-format(500, 22px, normal);
      }
    }
  }
}

@media screen and (max-width: 1150px) {
  .second-game-answers-list {
    &__item {
      &-title {
        @include text-format(500, 18px, normal);
      }

      &-box {
        @include size(150px, 35px);
        @include text-format(500, 22px, normal);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .correct-answers-block__title {
    width: 60%;
    &-inner {
      margin: 7px auto 30px;
      width: 100%;
      @include text-format(400, 22px, normal);
    }
  }
}

@media screen and (max-width: 520px) {
  .correct-answers-block {
    width: 100%;
    &__title {
      &-inner {
        width: 70%;
        @include text-format(400, 16px, normal);
      }
    }
  }

  .second-game-answers-list {
    gap: 6px;
    &__item {
      width: 45%;
      &-title {
        margin-bottom: 6px;
        @include text-format(500, 18px, 18px);
      }

      &-box {
        @include text-format(500, 18px, normal);
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .correct-answers-block {
    &__title {
      &-inner {
        width: 100%;
      }
    }
  }

  .second-game-answers-list {
    &__item {
      &-title {
        margin-bottom: 6px;
        @include text-format(500, 14px, 14px);
      }

      &-box {
        @include text-format(500, 14px, normal);
        height: 25px;
        width: 100%;
      }
    }
  }
}