@import '../../../../styles/sсss/mixins';
@import '../../../../styles/sсss/variables';

.result-progress-bar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 119px auto 80px;
  padding: 0;
  list-style-type: none;
  max-width: 660px;
  &_small-width {
    max-width: 330px;
  }
}

@media screen and (max-width: 700px) {
  .result-progress-bar {
    margin: 90px auto 60px;
  }
}

@media screen and (max-width: 480px) {
  .result-progress-bar {
    margin: 50px auto 40px;
  }
}
