@import '../../../../../../styles/sсss/mixins';
@import '../../../../../../styles/sсss/variables';

.progress-bar {
  &__step:nth-child(2) {
    border-radius: 6px 0 0 6px;
  }
  &__step:last-child {
    border-right: 1px solid #445200;
    border-radius: 0 6px 6px 0;
  }
  &__step {
    height: 100%;
    box-sizing: border-box;
    border: 0.8px solid #445200;
    border-right: none;
    &-two {
      border: 0.8px solid #98D1E3;
      &:last-child {
        border-right: 0.8px solid #98D1E3;
        border-radius: 0 6px 6px 0;
      }
    }
    &-three {
      border: 0.8px solid #064457;
      &:last-child {
        border-right: 0.8px solid #5142d1;
        border-radius: 0 6px 6px 0;
      }
    }
    &-four {
      border: 0.8px solid $fourth-game-primary;

      &:last-child {
        border-right: 0.8px solid $fourth-game-primary;
        border-radius: 0 6px 6px 0;
      }
    }
  }
}

.colored {
  &-one {
    background-color: $greenBackground;
    &-wrong {
      background-color: #FFA06A;
    }
  }
  &-two {
    background-color: #D5EBFA;
    &-wrong {
      background-color: #FFA06A;
    }
  }
  &-three {
    background-color: #9770B1;
    &-wrong {
      background-color: #FFA979;
    }
  }
}