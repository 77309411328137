@import '../../../../../../styles/sсss/mixins';

.pause-btn {
  border: none;
  background: none;
  cursor: pointer;
  position: absolute;
  top: 40px;
  left: 20px;

  &__image {
    @include size(45px, 45px);
    background-size: cover;
    overflow: hidden;
  }
}

@media screen and (max-width: 640px) {
  .pause-btn {
    top: 85px;
    left: 10px;

    &__image {
      @include size(30px, 30px);
    }
  }
}

