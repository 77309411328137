@import '../../../../../../../styles/sсss/mixins';
@import '../../../../../../../styles/sсss/variables';

.game-progress-bar {
  &__step:nth-child(2) {
    border-radius: 6px 0 0 6px;
  }
  &__step:last-child {
    border-right: 1px solid #445200;
    border-radius: 0 6px 6px 0;
  }
  &__step {
    height: 100%;
    box-sizing: border-box;
    border: 0.8px solid #445200;
    border-right: none;
    flex: 1;
  }
}